import React from 'react';

const authRoute = [
    {
        key: 'signIn',
        path: '/sign-in',
        component: React.lazy(() => import('views/auth/SignIn')),
        authority: [],
    },
    {
        key: 'signUp',
        path: '/sign-up',
        component: React.lazy(() => import('views/auth/SignUp')),
        authority: [],
    },
    {
        key: 'signUpObrigado',
        path: '/obrigado',
        component: React.lazy(() => import('views/auth/Welcome')),
        authority: [],
    },
    {
        key: 'forgotPassword',
        path: '/forgot-password',
        component: React.lazy(() => import('views/auth/ForgotPassword')),
        authority: [],
    },
    {
        key: 'resetPassword',
        path: '/trocarSenha/:idCliente',
        component: React.lazy(() => import('views/auth/ResetPassword')),
        authority: [],
    },
    {
        key: 'validarCadastro',
        path: '/validacao/:idCliente',
        component: React.lazy(() => import('views/auth/ValidarCadastro')),
        authority: [],
    },
    {
        key: 'agenda',
        path: '/agenda/:idCliente',
        component: React.lazy(() => import('views/calendario/CalendarioPublico')),
        authority: [],
    },
    {
        key: 'termos',
        path: '/termos',
        component: React.lazy(() => import('views/auth/Termos')),
        authority: [],
    },
    {
        key: 'questionario',
        path: '/questionario/:idFormulario/:idCliente',
        component: React.lazy(() => import('views/tecnicas/QuestionarioCliente')),
        authority: [],
    },
    {
        key: 'versao',
        path: '/versionAXD',
        component: React.lazy(() => import('views/welcome/Version')),
        authority: [],
    },
];

export default authRoute;
