import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { FormContextProvider, FormContextConsumer } from '../context';
import { useConfig } from '../../ConfigProvider';
import { SIZES, LAYOUT } from '../../utils/constant';

// Definindo a interface para as propriedades do componente
interface FormContainerProps {
    layout?: typeof LAYOUT[keyof typeof LAYOUT];
    size?: typeof SIZES[keyof typeof SIZES];
    labelWidth?: string | number;
    className?: string;
    children: ReactNode;
}

const FormContainer: React.FC<FormContainerProps> = (props) => {
    const { controlSize } = useConfig();

    const {
        children,
        labelWidth = 100, // Definindo valores padrão
        layout = LAYOUT.VERTICAL,
        size = SIZES.MD,
        className,
    } = props;

    const contextValue = {
        labelWidth,
        layout,
        size: size || controlSize,
    };

    return (
        <FormContextProvider value={contextValue}>
            <FormContextConsumer>
                {(context) => (
                    <div
                        className={classNames(
                            'form-container',
                            context.layout,
                            className,
                        )}
                    >
                        {children}
                    </div>
                )}
            </FormContextConsumer>
        </FormContextProvider>
    );
};

export default FormContainer;
