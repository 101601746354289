import ApiService from './ApiService';

export async function _ativarUsuario() {
    return ApiService.fetchData({
        url: '/rest/usuario/ativar',
        method: 'post',
    });
}


export async function _startOnboard(nometrilha) {
    return ApiService.fetchData({
        url: `/rest/terapeuta/onboard/start/${nometrilha}`,
        method: 'put',
    });
}


export async function _endOnboard() {
    return ApiService.fetchData({
        url: '/rest/terapeuta/onboard/end',
        method: 'put',
    });
}
