import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import Theme from 'components/template/Theme';
import Layout from 'components/layout';
import appConfig from 'configs/app.config';
import { RecoilRoot } from 'recoil';
import GlobalModal from './components/shared/Modal/index';
import history from './history';
import store, { persistor } from './store';
import { CalendarProvider } from './context/calendarContext';
import { TutorialProvider } from './context/tutorialContext'; // Importa o TutorialProvider

const environment = process.env.NODE_ENV;

function App() {

    return (
        <Provider store={store}>
            <RecoilRoot>
                <GlobalModal />
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter history={history}>
                        <Theme>
                            <CalendarProvider>
                                <TutorialProvider> 
                                    <Layout />
                                </TutorialProvider>
                            </CalendarProvider>
                        </Theme>
                    </BrowserRouter>
                </PersistGate>
            </RecoilRoot>
        </Provider>
    );
}

export default App;
