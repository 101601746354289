import React, { memo, useMemo, lazy, Suspense } from "react";
import { Loading } from "components/shared";
import { useSelector } from "react-redux";
import { useLocation, matchPath } from "react-router-dom";
import {
  LAYOUT_TYPE_CLASSIC,
  LAYOUT_TYPE_MODERN,
  LAYOUT_TYPE_SIMPLE,
  LAYOUT_TYPE_STACKED_SIDE,
  LAYOUT_TYPE_DECKED,
  LAYOUT_TYPE_BLANK,
} from "constants/theme.constant";
import useAuth from "utils/hooks/useAuth";
import useDirection from "utils/hooks/useDirection";
import { protectedRoutes } from "configs/routes.config";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const layouts = {
  [LAYOUT_TYPE_CLASSIC]: lazy(() => import("./ClassicLayout")),
  [LAYOUT_TYPE_MODERN]: lazy(() => import("./ModernLayout")),
  [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import("./StackedSideLayout")),
  [LAYOUT_TYPE_SIMPLE]: lazy(() => import("./SimpleLayout")),
  [LAYOUT_TYPE_DECKED]: lazy(() => import("./DeckedLayout")),
  [LAYOUT_TYPE_BLANK]: lazy(() => import("./BlankLayout")),
};

const Layout = () => {
  const layoutType = useSelector((state) => state.theme.layout.type);
  const { authenticated } = useAuth();
  const location = useLocation();

  useDirection();

  const currentRoute = useMemo(() => {
    return protectedRoutes.find((route) =>
      matchPath(route.path, location.pathname)
    );
  }, [location.pathname]);

  const AppLayout = useMemo(() => {
    if (authenticated) {
      if (
        location.pathname.startsWith("/welcome") ||
        location.pathname.startsWith("/perfilClear")
      ) {
        return lazy(() => import("./AuthLayout"));
      }
      return layouts[layoutType];
    }
    return lazy(() => import("./AuthLayout"));
  }, [layoutType, authenticated, location]);

  const TutorialComponent = currentRoute?.tutorialComponent || null;

  return (
    <Suspense
      fallback={
        <div className="flex flex-auto flex-col h-[100vh]">
          <Loading loading />
        </div>
      }
    >
      {AppLayout ? (
        <AppLayout tutorialComponent={TutorialComponent} />
      ) : (
        <div>Layout não encontrado</div>
      )}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Suspense>
  );
};

export default memo(Layout);
