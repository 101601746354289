import { Button, Dialog } from 'components/ui';
import { ConfigAgenda } from 'types/calendario/configAgenda';
import useDialog from 'views/calendario/hooks/useDialog';

interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  data: ConfigAgenda | null;
  onCancelAgenda: () => void;
}

const DialogConfirmarNaoComparecimento = ({
    isOpen,
    data,
    onCancelAgenda,
    onClose,
}: DialogProps) => {
    const { naoCompareceuConfirma } = useDialog();

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            onRequestClose={onClose}
            overlayClassName="z-40"
        >
            {data && (
                <h6 className="mb-4 font-medium">
                    Não Comparecimento: {data?.dataInicio}
                </h6>
            )}
            <p>
                Você está marcando a agenda com o não comparecimento do seu paciente.
                Confirma a ação??
            </p>

            <div className="text-right mt-6 flex flex-col md:flex-row gap-2">
                <Button
                    variant="solid"
                    type="button"
                    onClick={() => {
                        if (!data) return;
                        // naoCompareceuConfirma(data, onCancelAgenda);
                    }}
                >
                    Confirmar
                </Button>
                <Button onClick={onClose}>Cancelar</Button>
            </div>
        </Dialog>
    );
};

export default DialogConfirmarNaoComparecimento;
