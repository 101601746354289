import React, { ReactNode } from 'react';
import classNames from 'classnames';
import useThemeClass from 'utils/hooks/useThemeClass';
import { Link } from 'react-router-dom';

interface ActionLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    children?: ReactNode;
    className?: string;
    themeColor?: boolean;
    to?: string;
    href?: string;
}

const ActionLink: React.FC<ActionLinkProps> = (props) => {
    const {
        children,
        className,
        themeColor = true,
        to,
        href = '',
        ...rest
    } = props;

    const { textTheme } = useThemeClass();

    const classNameProps = {
        className: classNames(
            themeColor && textTheme,
            'hover:underline',
            className,
        ),
    };

    return to ? (
        <Link {...classNameProps} {...rest} to={to}>
            {children}
        </Link>
    ) : (
        <a href={href} {...classNameProps} {...rest}>
            {children}
        </a>
    );
};

export default ActionLink;
