import React from 'react';
import {
    HiCheckCircle,
    HiInformationCircle,
    HiExclamation,
    HiXCircle,
} from 'react-icons/hi';

interface IconSet {
    [key: string]: {
        color: string;
        icon: React.ReactNode;
    };
}

const ICONS: IconSet = {
    success: {
        color: 'text-emerald-400',
        icon: <HiCheckCircle />,
    },
    info: {
        color: 'text-blue-400',
        icon: <HiInformationCircle />,
    },
    warning: {
        color: 'text-yellow-400',
        icon: <HiExclamation />,
    },
    danger: {
        color: 'text-red-400',
        icon: <HiXCircle />,
    },
};

interface Props {
    type: string;
    custom?: React.ReactNode;
    iconColor?: string;
}

const StatusIcon = (props: Props) => {
    const { type, custom, iconColor } = props;

    const icon = ICONS[type];

    return (
        <span className={`text-2xl ${iconColor || icon.color}`}>
            {custom || icon.icon}
        </span>
    );
};

export default StatusIcon;
