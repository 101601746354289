const configDEV = {
    apiPrefix: 'https://tes.usecorpora.app.br/corpora',
    authenticatedEntryPath: '/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'pt-br',
    enableMock: false,
    nome: 'desenvolvimento',
    versao: 'dev',
};

const configTES = {
    apiPrefix: 'https://tes.usecorpora.app.br/corpora',
    authenticatedEntryPath: '/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'pt-br',
    enableMock: false,
    nome: 'teste',
    versao: 'tes',
};

const configPROD = {
    apiPrefix: 'https://usecorpora.app.br/corpora',
    authenticatedEntryPath: '/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'pt-br',
    enableMock: false,
    nome: 'producao',
    versao: 'p.0.8.18',
};

const appConfig = process.env.NODE_ENV === 'production' ? configPROD : configTES;

export default appConfig;
