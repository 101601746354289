import React, {
    forwardRef, useState, useEffect, ChangeEvent,
} from 'react';
import classNames from 'classnames';
import Spinner from '../Spinner';
import { useConfig } from '../ConfigProvider';

interface SwitcherProps {
    checked?: boolean;
    checkedContent?: React.ReactNode | string;
    className?: string;
    color?: string;
    defaultChecked?: boolean;
    disabled?: boolean;
    isLoading?: boolean;
    labelRef?: React.Ref<HTMLLabelElement>;
    name?: string;
    onChange?: (checked: boolean, event: ChangeEvent<HTMLInputElement>) => void;
    readOnly?: boolean;
    unCheckedContent?: React.ReactNode | string;
    field?: {
        value?: boolean;
    };
    [key: string]: any; // Para capturar outras props adicionais
}

const Switcher = forwardRef<HTMLInputElement, SwitcherProps>((props, ref) => {
    const {
        checked,
        checkedContent,
        className,
        color,
        defaultChecked,
        disabled,
        isLoading = false,
        labelRef,
        name,
        onChange,
        readOnly,
        unCheckedContent,
        field,
        ...rest
    } = props;

    const { themeColor, primaryColorLevel } = useConfig();

    const [switcherChecked, setSwitcherChecked] = useState<boolean>(!!defaultChecked || !!checked);

    useEffect(() => {
        if (typeof checked !== 'undefined') {
            setSwitcherChecked(checked);
        }
    }, [checked]);

    const getControlProps = () => {
        let checkedValue = switcherChecked ?? false;

        let controlProps: {
            value: boolean;
            checked?: boolean;
            defaultChecked?: boolean;
        } = { value: checkedValue };

        if (field) {
            checkedValue = typeof field.value === 'boolean' ? field.value : defaultChecked!;
            controlProps = { value: checkedValue, checked: checkedValue };
        }

        if (defaultChecked) {
            controlProps.defaultChecked = defaultChecked;
        }
        return controlProps;
    };

    const controlProps = getControlProps();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const nextChecked = !switcherChecked;

        if (typeof checked === 'undefined') {
            setSwitcherChecked(nextChecked);
            onChange?.(nextChecked, e);
        } else {
            onChange?.(switcherChecked, e);
        }
    };

    const switcherColor = color || `${themeColor}-${primaryColorLevel}`;

    const switcherClass = classNames(
        'switcher',
        (switcherChecked || controlProps.checked)
        && `switcher-checked bg-${switcherColor} dark:bg-${switcherColor}`,
        disabled && 'cursor-not-allowed',
        className,
    );

    return (
        <label ref={labelRef} className={switcherClass}>
            <input
                ref={ref}
                type="checkbox"
                disabled={disabled}
                readOnly={readOnly}
                onChange={handleChange}
                name={name}
                {...controlProps}
                {...field}
                {...rest as any}
            />
            {isLoading ? (
                <Spinner
                    className={classNames(
                        'switcher-toggle-loading',
                        switcherChecked
                            ? 'switcher-checked-loading'
                            : 'switcher-uncheck-loading',
                    )}
                />
            ) : (
                <div className="switcher-toggle" />
            )}
            <span className="switcher-content">
                {switcherChecked ? checkedContent : unCheckedContent}
            </span>
        </label>
    );
});

export default Switcher;
