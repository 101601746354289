export const MESES = [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
];
export const DIA_STR = [
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
    'Domingo',
];

export enum STATUS_AGENDA {
  REALIZADO = 'REALIZADO',
  PREVISTO = 'PREVISTO',
  CANCELADO = 'CANCELADO',
  CANCELADO_PROFISSIONAL = 'CANCELADO_PROFISSIONAL',
  NAO_COMPARECEU = 'NAO_COMPARECEU',
  CONFIRMADO = 'CONFIRMADO',
  REMOVIDO = 'REMOVIDO',
  RESERVADO = 'RESERVADO',
  CANCELADO_RESERVA= 'CANCELADO_RESERVA'
}

interface TypeStatusAgenda {
  [key: string]: string;
}

export const STATUS_AGENDA_CAPITALIZE: TypeStatusAgenda = {
    REALIZADO: 'Realizada',
    PREVISTO: 'Prevista',
    CANCELADO: 'Cancelada',
    CANCELADO_PROFISSIONAL: 'Cancelada',
    NAO_COMPARECEU: 'Não Compareceu',
    CONFIRMADO: 'Confirmada',
    REMOVIDO: 'Removida',
    CANCELADO_RESERVA: 'Reserva Expirada',
    RESERVADO: 'Reservada',
};

export const STATUS_AGENDA_TITLE: TypeStatusAgenda = {
    REALIZADO: 'Agenda Realizada',
    PREVISTO: 'Agenda Prevista',
    CANCELADO: 'Agenda Cancelada',
    CANCELADO_PROFISSIONAL: 'Agenda Cancelada pelo Profissional',
    NAO_COMPARECEU: 'Paciente não Compareceu',
    CONFIRMADO: 'Agenda Confirmada pelo Paciente',
    REMOVIDO: 'Agenda Removida',
};

export const STATUS_AGENDA_KEYS = [
    STATUS_AGENDA.PREVISTO,
    STATUS_AGENDA.REALIZADO,
    STATUS_AGENDA.CANCELADO,
    STATUS_AGENDA.NAO_COMPARECEU,
    STATUS_AGENDA.CONFIRMADO,
    STATUS_AGENDA.REMOVIDO,
    STATUS_AGENDA.CANCELADO_PROFISSIONAL,
    STATUS_AGENDA.RESERVADO,
    STATUS_AGENDA.CANCELADO_RESERVA,
];
