import { Button, Dialog } from 'components/ui';
import { ConfigAgenda } from 'types/calendario/configAgenda';
import useCalendar from 'views/calendario/hooks/useCalendar';
import useDialog from 'views/calendario/hooks/useDialog';

interface DialogProps {
  data: ConfigAgenda | null;
  values: ConfigAgenda;
  isOpen: boolean;
  onClose: () => void;
}

const DialogoEditarAgenda = ({
    data,
    values,
    isOpen,
    onClose,
}: DialogProps) => {
    const { botaoEditarAgendamentoClick } = useCalendar();

    /**
   *
   * @param edicaoTipo
   * 1: Somente esta
   * 2: Esta e as seguintes
   *
   */
    const handleEditarAgenda = (edicaoTipo: number) => {
        if (!data?.id) return;
        // apagarAgendaRecorrenteClick(data.id, edicaoTipo, onClose);
        botaoEditarAgendamentoClick(data, values, edicaoTipo, onClose);
    };

    const tipoAg: {
    [key: string]: string;
  } = {
      BLOQUEIO: 'bloqueio',
      NORMAL: 'agenda',
  };

    return (
        <Dialog
            overlayClassName="z-40"
            isOpen={isOpen}
            onClose={onClose}
            onRequestClose={onClose}
        >
            <h6 className="mb-4 font-medium">
                Edição de {tipoAg[data?.categoria as string]} {data?.dataInicio}
            </h6>
            <p>
                {data?.categoria === 'NORMAL' ? 'Esta' : 'Este'} é{' '}
                {data?.categoria === 'NORMAL' ? 'uma' : 'um'}{' '}
                {tipoAg[data?.categoria as string]} recorrente. O que deseja editar?
            </p>
            <div className="text-right mt-6 flex flex-col md:flex-row gap-2">
                <Button variant="solid" onClick={() => handleEditarAgenda(0)}>
                    Somente Esta
                </Button>
                <Button
                    variant="solid"
                    className=""
                    onClick={() => handleEditarAgenda(1)}
                >
                    Essa e as Seguintes
                </Button>
                <Button onClick={onClose}>Cancelar</Button>
            </div>
        </Dialog>
    );
};

export default DialogoEditarAgenda;
