import { combineReducers } from '@reduxjs/toolkit';
import common from './commonSlice';
import cliente from './clienteSlice';
import configAgenda from './configAgendaSlice';
import corporaConfig from './corporaConfigSlice';
import financeiro from './financeiroSlice';
import permissions from './permissionSlice';

const reducer = combineReducers({
    common,
    cliente,
    configAgenda,
    corporaConfig,
    financeiro,
    permissions,
});

export default reducer;
