import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import useMergedRef from '../hooks/useMergeRef';

export interface AvatarProps extends React.HTMLAttributes<HTMLSpanElement> {
    size?: 'sm' | 'md' | 'lg' | number;
    src?: string;
    srcSet?: string;
    shape?: 'rounded' | 'square' | 'circle';
    alt?: string;
    icon?: React.ReactNode;
    children?: React.ReactNode;
}

const Avatar = React.forwardRef<HTMLSpanElement, AvatarProps>((props, ref) => {
    const {
        size = 'md',
        src,
        srcSet,
        shape = 'rounded',
        alt,
        className,
        icon,
        children: initialChildren,
        ...rest
    } = props;

    const [scale, setScale] = useState(1);
    const avatarChildren = useRef<HTMLSpanElement | null>(null);
    const avatarNode = useRef<HTMLSpanElement | null>(null);

    const avatarMergeRef = useMergedRef(ref, avatarNode);

    const innerScale = () => {
        if (!avatarChildren.current || !avatarNode.current) {
            return;
        }
        const avatarChildrenWidth = avatarChildren.current.offsetWidth;
        const avatarNodeWidth = avatarNode.current.offsetWidth;
        if (avatarChildrenWidth === 0 || avatarNodeWidth === 0) {
            return;
        }
        setScale(
            avatarNodeWidth - 8 < avatarChildrenWidth
                ? (avatarNodeWidth - 8) / avatarChildrenWidth
                : 1,
        );
    };

    useEffect(() => {
        innerScale();
    }, [scale, initialChildren]);

    const sizeStyle = typeof size === 'number'
        ? {
            width: size,
            height: size,
            minWidth: size,
            lineHeight: `${size}px`,
            fontSize: icon ? size / 2 : 12,
        }
        : {};

    const classes = classNames(
        'avatar',
        `avatar-${shape}`,
        typeof size === 'string' ? `avatar-${size}` : '',
        className,
    );

    let children = initialChildren;

    if (src) {
        children = (
            <img
                className={`avatar-img avatar-${shape}`}
                src={src}
                srcSet={srcSet}
                alt={alt}
                loading="lazy"
            />
        );
    } else if (icon) {
        children = (
            <span className={classNames('avatar-icon', `avatar-icon-${size}`)}>
                {icon}
            </span>
        );
    } else {
        const childrenSizeStyle = typeof size === 'number' ? { lineHeight: `${size}px` } : {};
        const stringCentralized = {
            transform: `translateX(-50%) scale(${scale})`,
        };
        children = (
            <span
                className={`avatar-string ${
                    typeof size === 'number' ? '' : `avatar-inner-${size}`
                }`}
                style={{
                    ...childrenSizeStyle,
                    ...stringCentralized,
                    ...(typeof size === 'number' ? { height: size } : {}),
                }}
                ref={avatarChildren}
            >
                {children}
            </span>
        );
    }

    return (
        <span
            className={classes}
            style={{ ...sizeStyle, ...rest.style }}
            ref={avatarMergeRef}
            {...rest}
        >
            {children}
        </span>
    );
});

export default Avatar;
