export function criarFormatador(locale, currency) {
    return new Intl.NumberFormat(locale, { style: 'currency', currency });
}

export function formatarMoedaBRL(valor) {
    if (valor === null || valor === '') return '';

    const vl = valor.substring(valor.match(/\d/).index);
    return vl.replaceAll('.', '').replaceAll(',', '.').trim();
}

export function formatarMoedaBRL2(valor) {
    if (valor === null || valor === '') return '';

    const onlyDigits = valor
        .split('')
        .filter((s) => /\d/.test(s))
        .join('')
        .padStart(3, '0');

    return `${onlyDigits.slice(0, -2)}.${onlyDigits.slice(-2)}`;
}

export const mascaraMoeda = (event, locale, currency) => {
    const onlyDigits = event.target.value
        .split('')
        .filter((s) => /\d/.test(s))
        .join('')
        .padStart(3, '0');
    const digitsFloat = `${onlyDigits.slice(0, -2)}.${onlyDigits.slice(-2)}`;

    // event.target.value = new Intl.NumberFormat({style: 'currency'}).format(digitsFloat);
    event.target.value = maskCurrency(digitsFloat, locale, currency);
};

export const maskCurrency = (valor, locale = 'pt-br', currency = 'BRL') => {
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
    }).format(valor);
};

export const maskCurrency2 = (valor, locale = 'pt-br', currency = 'BRL') => {
    const onlyDigits = valor
        .split('')
        .filter((s) => /\d/.test(s))
        .join('')
        .padStart(3, '0');
    const digitsFloat = `${onlyDigits.slice(0, -2)}.${onlyDigits.slice(-2)}`;

    return maskCurrency(digitsFloat, locale, currency);
};

export const currencyFormatter = (
    valor,
    locale = 'pt-BR',
    currency = 'BRL',
) => {
    const Currency = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
    });

    return Currency.format(Number(valor));
};
