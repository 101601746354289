import {
    useState,
    forwardRef,
    useImperativeHandle,
    ForwardedRef,
} from 'react';
import { Button, Spinner } from 'components/ui';
import {
    FiCheck, FiX,
} from 'react-icons/fi';
import { useTutorial } from 'context/tutorialContext';

interface OwnProps {
    checkHandler?: () => void;
    closeHandler?: () => void;
}

const EditarCancelarBotoes = forwardRef((props: OwnProps, ref: ForwardedRef<unknown>) => {
    const [emEdicao, setEmEdicao] = useState(false);
    const { closeHandler, checkHandler } = props;
    const { isTutorialMode } = useTutorial();

    const submeterClick = () => {
        setEmEdicao(true);
        if (checkHandler) checkHandler();
    };

    useImperativeHandle(ref, () => ({
        erroSubmit() {
            setEmEdicao(false);
        },
    }));

    return (
        <div className="" style={{ right: '5px' }}>
            <div className="fixed bottom-2 right-2 flex justify-end sm:bottom-20 h-16">
                {isTutorialMode ? null :
                    <Button
                        size="lg"
                        shape="circle"
                        variant=""
                        className="mb-2 z-50"
                        type="button"
                        onClick={() => {
                            if (closeHandler) {
                                closeHandler();
                            }
                        }}
                        icon={<FiX />}
                    />
                }
                <Button
                    size="lg"
                    shape="circle"
                    variant="solid"
                    className="mb-2 z-50"
                    onClick={submeterClick}
                    type="button"
                    icon={<FiCheck />}
                >
                    {emEdicao && (
                        <span className="flex items-center justify-center">
                            <Spinner enableTheme={false} className="mr-1 animate-spin" />
                        </span>
                    )}
                </Button>
            </div>
        </div>
    );
});

export default EditarCancelarBotoes;
