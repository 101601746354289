import ApiService from './ApiService';

export async function _buscarClientesUsuario() {
    return ApiService.fetchData({
        url: '/rest/clientes',
        method: 'get',
    });
}

export async function _buscarClientes(tipoOrdenacao) {
    return ApiService.fetchData({
        url: `/rest/clientes/filtro/${tipoOrdenacao}`,
        method: 'get',
    });
}

export async function _buscarClientesPaginado(tipoOrdenacao, pagina) {
    return ApiService.fetchData({
        url: `/rest/clientes/filtro/${tipoOrdenacao}/${pagina}`,
        method: 'get',
    });
}

export async function _buscarClientesUsuarioPesquisa(pagina, nome) {
    return ApiService.fetchData({
        url: `/rest/clientes/search/${pagina}/${nome}`,
        method: 'get',
    });
}

// Busca por UUID
export async function _buscarClientesById(uuid) {
    return ApiService.fetchData({
        url: `/rest/clientes/${uuid}`,
        method: 'get',
    });
}

// Busca por ID
export async function _buscarClienteByIdUnico(idCliente) {
    return ApiService.fetchData({
        url: `/rest/clientes/id/${idCliente}`,
        method: 'get',
    });
}

export async function _salvarNovoCliente(dados) {
    return ApiService.fetchData({
        url: '/rest/clientes',
        method: 'post',
        data: dados,
    });
}

export async function _atualizarCliente(dados) {
    return ApiService.fetchData({
        url: `/rest/clientes/${dados.id}`,
        method: 'put',
        data: dados,
    });
}

export async function _deletarCliente(idCliente) {
    return ApiService.fetchData({
        url: `/rest/clientes/${idCliente}`,
        method: 'delete',
    });
}

export async function _arquivarCliente(idCliente) {
    return ApiService.fetchData({
        url: `/rest/clientes/arquivar/${idCliente}`,
        method: 'post',
    });
}
export async function _ativarCliente(idCliente) {
    return ApiService.fetchData({
        url: `/rest/clientes/ativar/${idCliente}`,
        method: 'post',
    });
}

export async function _toggleFavorito(idCliente) {
    return ApiService.fetchData({
        url: `/rest/clientes/favoritar/${idCliente}`,
        method: 'post',
    });
}

export async function _enviarMsgAtualizar(idCliente) {
    return ApiService.fetchData({
        url: `/rest/clientes/${idCliente}/msgAtualizar`,
    });
}
