export interface TutorialRoute {
    url: string;
    nome: string;
    textoEntrada?: string;
    abrirCadastro?: boolean;
}

export interface TutorialConfig {
    rotas: TutorialRoute[];
    telaFinal?: string;
    nomeTelaFinal?: string;
    nomeService?: string;
}

export const tutorialsConfig: Record<string, TutorialConfig> = {
};
