import appConfig from 'configs/app.config';
import ApiService from './ApiService';
import BaseService from './BaseService';

export const _buscarPerfilUsuario = () => {
    return ApiService.fetchData({
        url: '/rest/usuario/perfil',
        method: 'get',
    });
};

export const _salvarPerfilUsuario = (dados) => {
    return ApiService.fetchData({
        url: '/rest/usuario/perfil',
        method: 'post',
        data: dados,
    });
};

export const _salvarPerfilSecretaria = (dados) => {
    return ApiService.fetchData({
        url: '/rest/usuario/secretaria',
        method: 'post',
        data: dados,
    });
};

export const _buscarSecretariasLista = (dados) => {
    return ApiService.fetchData({
        url: '/rest/usuario/secretaria',
        method: 'get',
        data: dados,
    });
};

export const _deletarSecretaria = (id) => {
    return ApiService.fetchData({
        url: `/rest/usuario/secretaria/${id}`,
        method: 'delete',
    });
};

export const _finalizarOnboarding = () => {
    return ApiService.fetchData({
        url: '/rest/usuario/finalizarOnboarding',
        method: 'post',
    });
};

export const _salvarPerfilUsuarioSimplificado = (dados) => {
    return ApiService.fetchData({
        url: '/rest/usuario/perfilSimplificado',
        method: 'post',
        data: dados,
    });
};

export const _salvarUrlAgendaTerapeuta = (urlAgenda) => {
    return ApiService.fetchData({
        url: `/rest/terapeuta/urlAgenda/${urlAgenda}`,
        method: 'post',
    });
};

export const _atualizarUrlFotoPerfilTerapeuta = (dados) => {
    return ApiService.fetchData({
        url: '/rest/terapeuta/perfil/urlFoto',
        method: 'put',
        data: dados,
    });
};

export const _atualizarUrlLogo = (dados) => {
    return ApiService.fetchData({
        url: '/rest/terapeuta/perfil/urlLogo',
        method: 'put',
        data: dados,
    });
};

export const _buscarAgendaPublica = () => {
    return ApiService.fetchData({
        url: '/rest/terapeuta/agenda',
        method: 'get',
    });
};

export async function _enviarFotoPerfil(terapeutaId, file) {
    const x = new FormData();
    x.append('file', file);

    return BaseService.post(
        `${appConfig.apiPrefix}/rest/upload/perfil/${terapeutaId}`,
        x,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
}

export const _atualizarDataHora = () => {
    return ApiService.fetchData({
        url: '/rest/usuario/adh',
        method: 'put',
    });
};

export const _retoken = () => {
    return ApiService.fetchData({
        url: '/rest/usuario/retoken',
        method: 'post',
    });
};

export const _alterarSenha = (dados) => {
    return ApiService.fetchData({
        url: '/rest/usuario/trocarSenha',
        method: 'post',
        data: dados,
    });
};

export const _buscarTerapeutaInfo = () => {
    return ApiService.fetchData({
        url: '/rest/terapeuta/info',
        method: 'get',
    });
};

export const _atualizarTerapeutaInfo = (dados) => {
    return ApiService.fetchData({
        url: '/rest/terapeuta/info',
        method: 'put',
        data: dados,
    });
};

export async function _enviarArquivoMarca(file) {
    const x = new FormData();
    x.append('file', file);

    return BaseService.post(`${appConfig.apiPrefix}/rest/upload/marca`, x, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

export const _buscarTerapeuta = () => {
    return ApiService.fetchData({
        url: '/rest/terapeuta',
        method: 'get',
    });
};

export const _atualizarTerapeuta = (dados) => {
    return ApiService.fetchData({
        url: '/rest/terapeuta',
        method: 'put',
        data: dados,
    });
};

export const _logar = (texto) => {
    return ApiService.fetchData({
        url: '/rest/usuario/log',
        method: 'post',
        data: { txt: texto },
    });
};

export const _ativarFeedbackPaciente = (ativar) => {
    return ApiService.fetchData({
        url: `/rest/terapeuta/info/atualizarFeedback/${ativar}`,
        method: 'put',
    });
};

export const _buscarFeedbacks = () => {
    return ApiService.fetchData({
        url: '/rest/terapeuta/feedback',
        method: 'get',
    });
};