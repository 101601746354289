import React from 'react';

import authRoute from './authRoute';

export const publicRoutes = [...authRoute];

export const protectedRoutes = [
    {
        key: 'analise',
        path: '/analise/:idCliente',
        component: React.lazy(() => import('views/analise/Home')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'analiseEdit',
        path: '/analise/:idCliente/:idAnalise',
        component: React.lazy(() => import('views/analise/Home')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'anamnese',
        path: '/anamnese/:idCliente',
        component: React.lazy(() => import('views/tecnicas/formFixo/Anamnese')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'formDesignEdit',
        path: '/form/design/:idFormulario',
        component: React.lazy(() => import('views/tecnicas/FormPersonalizadoDesign')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'formDesign',
        path: '/form/design',
        component: React.lazy(() => import('views/tecnicas/FormPersonalizadoDesign')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'form',
        path: '/form/:idFormulario/:idCliente',
        component: React.lazy(() => import('views/tecnicas/FormPersonalizado')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'formView',
        path: '/formView/:idFormulario',
        component: React.lazy(() => import('views/tecnicas/FormPersonalizadoView')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'resumo',
        path: '/resumo/:idAnalise',
        component: React.lazy(() => import('views/analise/Resumo')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'meusClientes',
        path: '/meusClientes',
        component: React.lazy(() => import('views/clientes')),
        tutorialComponent: React.lazy(() => import('views/clientes/Tutorial')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'modelos',
        path: '/modelos',
        component: React.lazy(() => import('views/modelos')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'cliente',
        path: '/cliente/:idCliente',
        component: React.lazy(() => import('views/clientes/DadosCliente')),
        authority: [],
        showFooter: false,
    },
    {
        key: 'clienteProfile',
        path: '/cliente/profile',
        component: React.lazy(() => import('views/clientes/DadosCliente')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'clientePassword',
        path: '/cliente/password',
        component: React.lazy(() => import('views/clientes/DadosCliente')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'agenda',
        path: '/calendario',
        component: React.lazy(() => import('views/calendario')),
        tutorialComponent: React.lazy(() => import('views/calendario/TutorialAgenda')),
        authority: [],
        showFooter: false,
    },
    {
        key: 'welcome',
        path: '/welcome',
        component: React.lazy(() => import('views/welcome')),
        authority: [],
        showFooter: false,
    },
    {
        key: 'financeiro.lancamentos',
        path: '/lancamentos',
        component: React.lazy(() => import('views/financeiro')),
        tutorialComponent: React.lazy(() => import('views/financeiro/Tutorial')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'financeiro.lancamentos',
        path: '/lancamentos/cobrancaEmLote',
        component: React.lazy(() => import('views/financeiro/cobranca/CobrancaLote')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'financeiro.lancamentos',
        path: '/lancamentos/cobrancaIndividual/:idLancamento',
        component: React.lazy(() => import('views/financeiro/cobranca')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'cobrança',
        path: '/onboarding',
        component: React.lazy(() => import('views/financeiro/onboarding')),
        tutorialComponent: React.lazy(() => import('views/financeiro/onboarding/Tutorial')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'cobrancaInfo',
        path: '/cobrancaInfo',
        component: React.lazy(() => import('views/configuracoes/configSelects')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'perfil',
        path: '/perfil',
        component: React.lazy(() => import('views/account/perfil')),
        tutorialComponent: React.lazy(() => import('views/account/perfil/Tutorial')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'historiconotificacoes',
        path: '/notificacoes',
        component: React.lazy(() => import('views/account/HistoricoNotificacoes')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'configs.agenda',
        path: '/configAgenda',
        component: React.lazy(() => import('views/calendario/ConfigAgenda')),
        tutorialComponent: React.lazy(() => import('views/calendario/TutorialConfigAgenda')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'configs.localidade',
        path: '/localidades',
        component: React.lazy(() => import('views/configuracoes/localidades')),
        tutorialComponent: React.lazy(() => import('views/configuracoes/localidades/Tutorial')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'configs.servico',
        path: '/Servicos',
        component: React.lazy(() => import('views/configuracoes/servicos')),
        tutorialComponent: React.lazy(() => import('views/configuracoes/servicos/Tutorial')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'suporte',
        path: '/suporte',
        component: React.lazy(() => import('views/account/suporte')),
        authority: [],
        showFooter: true,
    },
    // {
    //     key: 'senha',
    //     path: '/alterarSenha',
    //     component: React.lazy(() => import('views/account/AlterarSenha')),
    //     authority: [],
    //     showFooter: true,
    // },
    {
        key: 'senha',
        path: '/alterarSenha',
        component: React.lazy(() => import('views/account/AlterarSenha')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'perfilClear',
        path: '/perfilClear',
        component: React.lazy(() => import('views/account/perfil/PerfilClear')),
        authority: [],
        showFooter: false,
    },
    {
        key: 'planos',
        path: '/planos',
        component: React.lazy(() => import('views/planos')),
        authority: [],
        showFooter: false,
    },
    {
        key: 'tecnicas',
        path: '/tecnicas',
        component: React.lazy(() => import('views/tecnicas/CorporaStore')),
        authority: [],
        showFooter: false,
    },
    {
        key: 'psicologia',
        path: '/psicologia/:idFormulario/:idCliente',
        component: React.lazy(() => import('views/tecnicas/psicologia/TecnicasPsicologia')),
        authority: [],
        showFooter: false,
    },
    {
        key: 'psicologiaResultado',
        path: '/psicologia/:idFormulario',
        component: React.lazy(() => import('views/tecnicas/psicologia/TecnicasPsicologia')),
        authority: [],
        showFooter: false,
    },
    {
        key: 'formFixo',
        path: '/formFixo/:idFormulario/:idCliente',
        component: React.lazy(() => import('views/tecnicas/formFixo/FormFixoChooser')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'formFixoResultado',
        path: '/formFixo/:idFormulario',
        component: React.lazy(() => import('views/tecnicas/formFixo/FormFixoChooser')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'termos',
        path: '/termosUso',
        component: React.lazy(() => import('views/auth/Termos')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'configs.perfil',
        path: '/configuracoes',
        component: React.lazy(() => import('views/configuracoes/ConfiguracoesView')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'dashboard',
        path: '/dashboard',
        component: React.lazy(() => import('views/dashboard')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'indique',
        path: '/indique',
        component: React.lazy(() => import('views/mgm')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'sair',
        path: '/sair',
        component: React.lazy(() => import('views/account/sair')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'configs.importacoes',
        path: '/importacoes',
        component: React.lazy(() => import('views/configuracoes/Importacoes')),
        authority: [],
        showFooter: true,
    },
    {
        key: 'onboardAcompanhado',
        path: '/onboardingAcompanhado',
        component: React.lazy(() => import('views/configuracoes/OnboardingAcompanhado')),
        authority: [],
        showFooter: true,
    },
    // {
    //     key: 'feedback',
    //     path: '/feedback',
    //     component: React.lazy(() => import('views/configuracoes/feedback/Feedback')),
    //     authority: [],
    //     showFooter: true,
    // },
];
