import { useState, useEffect } from 'react';
import { _buscarMeioPagamentoInfo } from 'services/ContaPagamentoService';

interface MeioPagamentoInfo {
    id: number;
    nome: string;
    ativo: boolean;
}

interface ApiError extends Error {
    response?: {
        status: number;
        data?: any;
    };
}

const useMeioPagamentoInfo = () => {
    const [data, setData] = useState<MeioPagamentoInfo[] | null>(null);
    const [error, setError] = useState<ApiError | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await _buscarMeioPagamentoInfo();
                setData(response);
            } catch (err: unknown) {
                const apiError = err as ApiError;
                setError(apiError);
            }
        };

        fetchData();
    }, []);

    return { data, error };
};

export default useMeioPagamentoInfo;
