import React, {
    CSSProperties,
    FormEvent,
    ForwardedRef,
    ReactNode,
    useContext,
} from 'react';
import {
    motion,
    AnimatePresence,
    VariantLabels,
    TargetAndTransition,
} from 'framer-motion';
import { MenuContextProvider } from './context/menuContext';
import useUncertainRef from '../hooks/useUncertainRef';
import DropdownMenuContext, {
    useDropdownMenuContext,
    DropdownMenuContextProvider,
} from './context/dropdownMenuContext';
import useUniqueId from '../hooks/useUniqueId';
import { Placement } from '../../../types/ui';

interface OwnProps {
  placement: Placement;
  activeKey?: string;
  onSelect?: (eventKey: string, e: FormEvent) => void;
  children?: ReactNode;
  hidden?: boolean;
  menuClass?: string;
  className?: string;
  onToggle?: (_: any, e: FormEvent) => void;
}

const Menu = React.forwardRef((props: OwnProps, ref: ForwardedRef<unknown>) => {
    const {
        children,
        activeKey,
        onSelect,
        hidden,
        placement,
        menuClass,
        ...rest
    } = props;

    const menuRef = useUncertainRef(ref);
    const menuId = useUniqueId('menu-');
    const menuControl = useDropdownMenuContext(menuRef);

    const getTransform = (deg: number) => {
        const rotate = `rotateX(${deg}deg)`;
        if (placement && placement.includes('center')) {
            return `${rotate} translateX(-50%)`;
        }
        return rotate;
    };

    const enterStyle: CSSProperties = {
        opacity: 1,
        visibility: 'visible',
        transform: getTransform(0),
    };
    const exitStyle: CSSProperties = {
        opacity: 0,
        visibility: 'hidden',
        transform: getTransform(40),
    };
    const initialStyle = exitStyle;

    return (
        <MenuContextProvider
            value={{
                activeKey,
                onSelect,
            }as any}
        >
            <DropdownMenuContextProvider value={menuControl}>
                <AnimatePresence exitBeforeEnter>
                    {!hidden && (
                        <motion.ul
                            id={menuId}
                            ref={menuRef}
                            initial={initialStyle as boolean | VariantLabels}
                            animate={enterStyle as boolean | VariantLabels}
                            exit={exitStyle as VariantLabels | TargetAndTransition | undefined}
                            transition={{ duration: 0.15, type: 'tween' }}
                            className={menuClass}
                            {...rest}
                        >
                            {children}
                        </motion.ul>
                    )}
                </AnimatePresence>
            </DropdownMenuContextProvider>
        </MenuContextProvider>
    );
});

export default Menu;
