import React, { useEffect, useState } from 'react';
import { HiChevronDown } from 'react-icons/hi';

interface IAccordionProps {
    Header: React.ReactNode;
    children: React.ReactNode;
    expanded?: boolean;
    feedbackState?: (value: boolean) => void;
}

function Accordion({
    Header,
    children,
    expanded,
    feedbackState,
}: IAccordionProps) {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (expanded === true) {
            setIsOpen(true);
        }

        if (expanded === false) {
            setIsOpen(false);
        }
    }, [expanded]);

    const handleState = () => {
        setIsOpen(!isOpen);
        feedbackState?.(!isOpen);
    };

    return (
        <div className="border border-gray-300 rounded-xl overflow-hidden mb-2">
            <button
                onClick={handleState}
                className="w-full text-left px-4 py-2 bg-gray-100 hover:bg-gray-200 transition-colors duration-200 focus:outline-none relative"
            >
                {Header}
                <span className={`transform transition-transform duration-300 hidden absolute right-2 top-1/2 translate-y-[-50%] sm:block ${isOpen ? 'rotate-180' : ''}`}>
                    <HiChevronDown size={20} />
                </span>
            </button>
            <div
                className={`transition-max-height duration-500 ease-in-out overflow-hidden ${isOpen ? 'max-h-screen' : 'max-h-0'}`}
            >
                {children}
            </div>
        </div>
    );
}

export default Accordion;
