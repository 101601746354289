import { ForwardRefExoticComponent, RefAttributes } from 'react';
import Menu, { MenuProps } from './Menu';
import MenuItem from './MenuItem';
import MenuCollapse from './MenuCollapse';
import MenuGroup from './MenuGroup';

// Definindo os tipos para o Menu estendido
interface MenuComponent
  extends ForwardRefExoticComponent<MenuProps & RefAttributes<HTMLElement>> {
  MenuItem: typeof MenuItem;
  MenuCollapse: typeof MenuCollapse;
  MenuGroup: typeof MenuGroup;
}

// Atribuindo os subcomponentes ao Menu
const MenuWithSubComponents = Menu as MenuComponent;

MenuWithSubComponents.MenuItem = MenuItem;
MenuWithSubComponents.MenuCollapse = MenuCollapse;
MenuWithSubComponents.MenuGroup = MenuGroup;

export default MenuWithSubComponents;
