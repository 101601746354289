import React, { forwardRef, ReactNode } from 'react';
import classNames from 'classnames';
import { MenuContextProvider } from './context/menuContext';
import { useConfig } from '../ConfigProvider';

// Definição das propriedades aceitas pelo componente
export interface MenuProps extends React.HTMLAttributes<HTMLElement> {
    children: ReactNode;
    className?: string;
    defaultActiveKeys?: string[];
    defaultExpandedKeys?: string[];
    menuItemHeight?: string | number;
    onMenuSelect?: (key: string) => void;
    sideCollapsed?: boolean;
    variant?: 'light' | 'dark' | 'themed' | 'transparent';
}

// Componente com forwardRef tipado
const Menu = forwardRef<HTMLElement, MenuProps>((props, ref) => {
    const {
        children,
        className,
        defaultActiveKeys = [],
        defaultExpandedKeys = [],
        menuItemHeight = 40,
        onMenuSelect,
        sideCollapsed = false,
        variant = 'light',
        ...rest
    } = props;

    const menuDefaultClass = 'menu';

    const { themeColor, primaryColorLevel } = useConfig();

    const menuColor = () => {
        if (variant === 'themed') {
            return `bg-${themeColor}-${primaryColorLevel} ${menuDefaultClass}-${variant}`;
        }
        return `${menuDefaultClass}-${variant}`;
    };

    const menuClass = classNames(menuDefaultClass, menuColor(), className);

    return (
        <nav ref={ref} className={menuClass} {...rest}>
            <MenuContextProvider
                value={{
                    onMenuSelect,
                    menuItemHeight,
                    variant,
                    sideCollapsed,
                    defaultExpandedKeys,
                    defaultActiveKeys,
                }}
            >
                {children}
            </MenuContextProvider>
        </nav>
    );
});

export default Menu;
