import { Button, Dialog } from 'components/ui';
import { ConfigAgenda } from 'types/calendario/configAgenda';

interface DialogProps {
  isOpen: boolean;
  data: ConfigAgenda | null;
  onClose: () => void;
  onClickReagendar: () => void;
  onClickCancelar: () => void;
}

const DialogNaoCompareceu = ({
    isOpen,
    data,
    onClose,
    onClickReagendar,
    onClickCancelar,
}: DialogProps) => {
    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            onRequestClose={onClose}
            overlayClassName="z-40"
        >
            <h6 className="mb-4 font-medium">
                Não Comparecimento {data?.dataInicio}
            </h6>
            <p>O seu paciente não compareceu ao atendimento. O que deseja fazer?</p>

            <div className="text-right mt-6 flex flex-col md:flex-row gap-2">
                <Button
                    variant="solid"
                    onClick={onClickReagendar}
                    title="Reagendar horário"
                >
                    Reagendar
                </Button>
                <Button
                    variant="solid"
                    onClick={onClickCancelar}
                    title="Marcar agenda como NAO COMPARECIMENTO do paciente"
                >
                    Não Compareceu
                </Button>
            </div>
        </Dialog>
    );
};

export default DialogNaoCompareceu;
