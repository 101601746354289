import { Button, Dialog } from 'components/ui';
import { ConfigAgenda } from 'types/calendario/configAgenda';
import useDialog from 'views/calendario/hooks/useDialog';

interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  data: ConfigAgenda | null;
  onSucess: () => void;
}

const DialogConfirmarRecuperacao = ({
    isOpen,
    onClose,
    data,
    onSucess,
}: DialogProps) => {
    const { recuperacaoConfirmaClick } = useDialog();

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            onRequestClose={onClose}
            overlayClassName="z-40"
        >
            {data && (
                <h6 className="mb-4 font-medium">
                    Confirmação de recuperação: {data?.dataInicio}
                </h6>
            )}
            <p>Confirma a recuperação da agenda?</p>

            <div className="text-right mt-6 flex flex-col md:flex-row gap-2">
                <Button
                    variant="solid"
                    onClick={() => {
                        if (!data) return;
                        recuperacaoConfirmaClick(data, onSucess);
                    }}
                >
                    Confirmar
                </Button>
                <Button onClick={onClose}>Cancelar</Button>
            </div>
        </Dialog>
    );
};

export default DialogConfirmarRecuperacao;
