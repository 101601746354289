import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    avatar: '',
    userName: '',
    email: '',
    roles: '',
    authority: [],
    uid: '',
    idRef: '',
    status: '',
    plano: {
        plano: '',
        recorrencia: '',
        desabilitarEm: '',
    },
    profissional: {
        nome: '',
        email: '',
    },
};

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, action) => action.payload,
        setStatus: (state, action) => {
            state.status = action.payload;
        },
        userLoggedOut: () => initialState,

    },
});

export const { setUser, setStatus } = userSlice.actions;

export default userSlice.reducer;
