import { Button, Dialog } from 'components/ui';
import { ConfigAgenda } from 'types/calendario/configAgenda';
import useDialog from 'views/calendario/hooks/useDialog';

interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  data: ConfigAgenda | null;
  onSucess: () => void;
}

const DialogConfirmarCancelamentoResponsavel = ({
    data,
    isOpen,
    onClose,
    onSucess,
}: DialogProps) => {
    const { cancelamentoConfirma } = useDialog();

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            onRequestClose={onClose}
            overlayClassName="z-40"
        >
            {data && (
                <h6 className="mb-4 font-medium">Cancelamento: {data?.dataInicio}</h6>
            )}
            <p className="mb-5">Quem é o responsável pelo cancelamento?</p>

            <div className="text-right mt-6 flex flex-col md:flex-row gap-2">
                <Button
                    variant="solid"
                    onClick={() => {
                        if (!data) return;
                        // cancelamentoConfirma(data, onSucess, false);
                    }}
                >
                    Profissional
                </Button>
                <Button
                    variant="solid"
                    onClick={() => {
                        if (!data) return;
                        // cancelamentoConfirma(data, onSucess, true);
                    }}
                >
                    Paciente
                </Button>
                <Button onClick={onClose}>Cancelar</Button>
            </div>
        </Dialog>
    );
};

export default DialogConfirmarCancelamentoResponsavel;
