import BaseService from './BaseService';

const ApiService = {
    fetchData(param) {
        return new Promise((resolve, reject) => {
            BaseService(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((errors) => {
                    if (param.onError) {
                        param.onError(errors?.response || errors);
                    }
                    reject(errors);
                });
        });
    },
};

export default ApiService;
