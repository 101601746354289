import { Button, Dialog, Switcher } from 'components/ui';
import { ConfigAgenda } from 'types/calendario/configAgenda';
import useDialog from 'views/calendario/hooks/useDialog';
import { useState, useEffect } from 'react';
import useMeioPagamentoInfo from 'views/calendario/hooks/usePaymentInfo';

interface DialogProps {
    isOpen: boolean;
    onClose: () => void;
    data: ConfigAgenda | null;
    onSucess: () => void;
}

const DialogConfirmarRealizar = ({
    data,
    isOpen,
    onClose,
    onSucess,
}: DialogProps) => {
    const { realizarConfirmaClick } = useDialog();
    const { data: meioPagamentoInfo, error } = useMeioPagamentoInfo(); 

    const [cobrar, setCobrar] = useState<number>(0);
    const [showError, setShowError] = useState<boolean>(false);

    const isValorInvalid = !data?.valor || isNaN(Number(data.valor)) || Number(data.valor) === 0;
    
    const isDisabled = error?.response?.status === 400 || isValorInvalid;

    useEffect(() => {
        if (isOpen) {
            setCobrar(0);
            setShowError(false);
        }
    }, [isOpen]);

    const handleOptionChange = (value: number) => {
        setCobrar(value);
        setShowError(false);
    };

    const handleConfirm = () => {
        if (cobrar === 0) {
            setShowError(true);
            return;
        }

        if (data) {
            realizarConfirmaClick(data, onSucess, cobrar);
        }
    };

   

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            onRequestClose={onClose}
            overlayClassName="z-40"
        >
            <h6 className="mb-4 font-medium">Marcar agenda como realizada?</h6>
            <p>{data?.dataInicio}</p>
            <p>
                Após marcar a agenda como <strong>REALIZADA</strong>, não será possível
                editar as informações do agendamento.
            </p>

            <div className="my-4">
                <div className="flex flex-col gap-3">
                    <div className="flex items-center">
                        <Switcher
                            checked={cobrar === 2}
                            onChange={() => handleOptionChange(2)}
                            disabled={isDisabled}
                        />
                        <span
                            className={`ml-3 ${isDisabled ? 'text-gray-300' : 'text-gray-900'}`}
                            title={isDisabled ? 'Você ainda não aderiu ao Corpora Bank' : ''}
                        >
                            Aplicar cobrança pela Corpora agora
                        </span>
                    </div>
                    <div className="flex items-center">
                        <Switcher
                            checked={cobrar === 3}
                            onChange={() => handleOptionChange(3)}
                        />
                        <span className="ml-3">Vou cobrar o paciente manualmente</span>
                    </div>
                    <div className="flex items-center">
                        <Switcher
                            checked={cobrar === 4}
                            onChange={() => handleOptionChange(4)}
                        />
                        <span className="ml-3">Já recebi o valor do paciente</span>
                    </div>
                </div>
                {showError && (
                    <span className="text-red-500 mt-2 block">
                        Selecione uma opção antes de confirmar.
                    </span>
                )}
            </div>

            <div className="text-right mt-6 flex flex-col md:flex-row gap-2">
                <Button variant="solid" onClick={handleConfirm}>
                    Confirmar
                </Button>
                <Button onClick={onClose}>Cancelar</Button>
            </div>
        </Dialog>
    );
};

export default DialogConfirmarRealizar;
