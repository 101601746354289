import { Button, Dialog, Switcher } from 'components/ui';
import { ConfigAgenda } from 'types/calendario/configAgenda';
import useDialog from 'views/calendario/hooks/useDialog';
import useMeioPagamentoInfo from 'views/calendario/hooks/usePaymentInfo';
import { useEffect, useState } from 'react';

interface DialogProps {
    isOpen: boolean;
    onClose: () => void;
    data: ConfigAgenda | null;
    onCancelAgenda: () => void;
}

const DialogNaoCompareceuCobranca = ({
    data,
    isOpen,
    onClose,
    onCancelAgenda,
}: DialogProps) => {
    const { naoCompareceuConfirma } = useDialog();
    const { data: meioPagamentoInfo, error } = useMeioPagamentoInfo(); 

    const [cobrar, setCobrar] = useState<number>(1);
    const [enviarMensagem, setEnviarMensagem] = useState<number>(0);
    const [showError, setShowError] = useState<boolean>(false);

    const isValorInvalid = !data?.valor || isNaN(Number(data.valor)) || Number(data.valor) === 0;
    
    const isDisabled = error?.response?.status === 400 || isValorInvalid;

    useEffect(() => {
        if (isOpen) {
            setCobrar(1);
            setEnviarMensagem(0);
            setShowError(false);
        }
    }, [isOpen]);

    const handleConfirm = () => {
        if (cobrar === 0) {
            setShowError(true);
            return;
        }

        if (data) {
            naoCompareceuConfirma(data, onCancelAgenda, cobrar, enviarMensagem);
        }
    };

    const handleCobrarChange = (value: number) => {
        setCobrar(value);
        setShowError(false);
    };

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            onRequestClose={onClose}
            overlayClassName="z-40"
        >
            <div className="p-6 bg-white">
                <h6 className="mb-4 font-medium">O seu paciente não compareceu ao atendimento.</h6>

                <div className="mt-4 mb-8">
                    <div className="flex items-center">
                        <Switcher
                            checked={cobrar === 1}
                            onChange={() => handleCobrarChange(cobrar === 1 ? 0 : 1)} 
                        />
                        <span className="ml-3">Excluir o lançamento. Não irei cobrar</span>
                    </div>

                    {cobrar !== 1 && (
                        <>
                            <span className="block mt-4 mb-2 font-medium">Escolha uma das opções abaixo:</span>
                            <div className="flex flex-col gap-3">
                                <div className="flex items-center">
                                    <Switcher
                                        checked={cobrar === 2}
                                        onChange={() => handleCobrarChange(2)}
                                        disabled={isDisabled}
                                    />
                                    <span
                                        className={`ml-3 ${isDisabled ? 'text-gray-300' : 'text-gray-900'}`}
                                        title={isDisabled ? 'Você ainda não aderiu ao Corpora Bank' : ''}
                                    >
                                        Aplicar cobrança pela Corpora agora
                                    </span>
                                </div>
                                <div className="flex items-center">
                                    <Switcher
                                        checked={cobrar === 3}
                                        onChange={() => handleCobrarChange(3)}
                                    />
                                    <span className="ml-3">Vou cobrar o paciente manualmente</span>
                                </div>
                                <div className="flex items-center">
                                    <Switcher
                                        checked={cobrar === 4}
                                        onChange={() => handleCobrarChange(4)}
                                    />
                                    <span className="ml-3">Já recebi o valor do paciente</span>
                                </div>
                            </div>
                        </>
                    )}

                    {showError && (
                        <span className="text-red-500 mt-2 block">
                            Selecione uma opção de cobrança.
                        </span>
                    )}
                </div>

                <div className="flex items-center gap-4 my-4">
                    <div className="flex items-center w-full">
                        <Switcher
                            checked={enviarMensagem === 1}
                            onChange={() => setEnviarMensagem(enviarMensagem === 1 ? 0 : 1)}
                        />
                        <span className="ml-2">Enviar mensagem de reagendamento</span>
                    </div>
                </div>

                <p>
                    Você está marcando a agenda com o não comparecimento do seu paciente.
                    Confirma a ação?
                </p>

                <div className="text-right mt-6 flex flex-col md:flex-row gap-2">
                    <Button variant="solid" onClick={handleConfirm}>
                        Confirmar
                    </Button>
                    <Button onClick={onClose}>Cancelar</Button>
                </div>
            </div>
        </Dialog>
    );
};

export default DialogNaoCompareceuCobranca;
