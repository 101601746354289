import React, {
    Children, cloneElement, Fragment, ReactNode,
} from 'react';
import classNames from 'classnames';
import Avatar from '../Avatar';
import Tooltip from '../Tooltip';

interface GroupContainerProps {
    children: ReactNode;
    chained?: boolean;
    className?: string;
}

const GroupContainer: React.FC<GroupContainerProps> = ({ children, chained, className }) => (
    <div className={classNames('avatar-group', chained && 'avatar-group-chained', className)}>
        {children}
    </div>
);

export interface AvatarGroupProps {
    maxCount?: number;
    chained?: boolean;
    className?: string;
    omittedAvatarTooltip?: boolean;
    onOmittedAvatarClick?: () => void;
    omittedAvatarProps?: React.HTMLAttributes<HTMLDivElement>;
    omittedAvatarContent?: ReactNode;
    children: ReactNode;
}

const AvatarGroup: React.FC<AvatarGroupProps> = ({
    maxCount = 3,
    chained = false,
    className,
    omittedAvatarTooltip = false,
    onOmittedAvatarClick,
    omittedAvatarProps,
    omittedAvatarContent,
    children,
}) => {
    const childCount = Children.count(children);

    const childWithKey = Children.toArray(children).map((child, index) => cloneElement(child as React.ReactElement, {
        key: `grouped-avatar-${index}`,
    }));

    if (maxCount && maxCount < childCount) {
        const childToShow = childWithKey.slice(0, maxCount);
        const overflowCount = childCount - maxCount;
        const avatar = (
            <Avatar
                className={onOmittedAvatarClick ? 'cursor-pointer' : ''}
                onClick={() => onOmittedAvatarClick?.()}
                {...omittedAvatarProps}
            >
                {omittedAvatarContent || `+${overflowCount}`}
            </Avatar>
        );

        childToShow.push(
            omittedAvatarTooltip ? (
                <Tooltip key="avatar-more-tooltip" title={`${overflowCount} More`}>
                    <>{avatar}</>
                </Tooltip>
            ) : (
                <Fragment key="avatar-more-tooltip">{avatar}</Fragment>
            ),
        );

        return (
            <GroupContainer className={className} chained={chained}>
                {childToShow}
            </GroupContainer>
        );
    }

    return (
        <GroupContainer className={className} chained={chained}>
            {children}
        </GroupContainer>
    );
};

export default AvatarGroup;
