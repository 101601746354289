import React from 'react';
import TopicItem from './TopicItem';

interface TopicsProps {
  children: React.ReactNode;
}

const Topics: React.FC<TopicsProps> & { Item: typeof TopicItem } = ({ children }) => {
    return <div>{children}</div>;
};

Topics.Item = TopicItem;

export default Topics;
