import React, {
    createContext, useCallback, useContext, useEffect, useState,
} from 'react';
import { DateTime } from 'luxon';

const CalendarContext = createContext();

export const useCalendarContext = () => useContext(CalendarContext);

export const CalendarProvider = ({ children }) => {
    const [dates, setDates] = useState([]);
    const [showNotAttended, setShowNotAttended] = useState(false);
    const [lastDate, setLastDate] = useState(null);
    const [firstDate, setFirstDate] = useState(null);
    const [events, setEvents] = useState({});
    const [shouldRefreshEvents, setShouldRefreshEvents] = useState(false);
    const [filterHolidays, setFilterHolidays] = useState(false);
    const [showMonthlyView, setShowMonthlyView] = useState(false);
    const [view, setView] = useState(() => {
        return localStorage.getItem('AGENDA_VIEW') || 'calendar';
    });

    const [filterRealized, setFilterRealized] = useState(
        JSON.parse(localStorage.getItem('filterRealized')) ?? true
    );
    const [filterCanceled, setFilterCanceled] = useState(
        JSON.parse(localStorage.getItem('filterCanceled')) ?? false
    );
    const [filterNotAttended, setFilterNotAttended] = useState(
        JSON.parse(localStorage.getItem('filterNotAttended')) ?? false
    );
    const [showWeekend, setShowWeekend] = useState(
        JSON.parse(localStorage.getItem('showWeekend')) ?? true
    );

    const toggleFilterRealized = () => setFilterRealized((prev) => !prev);
    const toggleShowWeekend = () => setShowWeekend((prev) => !prev);
    const toggleFilterCanceled = () => setFilterCanceled((prev) => !prev);
    const toggleFilterHolidays = () => setFilterHolidays((prev) => !prev);
    const toggleFilterNotAttended = () => setFilterNotAttended((prev) => !prev);
    const triggerRefresh = () => setShouldRefreshEvents(true);

    // Consolidated useEffect for localStorage updates
    useEffect(() => {
        const storageUpdates = {
            showWeekend,
            filterRealized,
            filterCanceled,
            filterNotAttended,
            filterHolidays,
            AGENDA_VIEW: view,
        };

        Object.entries(storageUpdates).forEach(([key, value]) => {
            localStorage.setItem(key, JSON.stringify(value));
        });
    }, [
        showWeekend,
        filterRealized,
        filterCanceled,
        filterNotAttended,
        filterHolidays,
        view,
    ]);

    const setarAgendaTipo = (agendaTipo) => {
        setView(agendaTipo);
    };

    useEffect(() => {
        setShowNotAttended(filterCanceled);
    }, [filterCanceled]);

    const generateDatesRange = useCallback((startDate, days) => {
        const dates = [];
        for (let i = 0; i < days; i++) {
            dates.push(startDate.plus({ days: i }).toFormat('yyyyMMdd'));
        }
        return dates;
    }, []);

    const clearDates = () => {
        setFirstDate(null);
        setLastDate(null);
    };

    const resetDates = () => {
        const today = DateTime.now();
        const inicioMesAnterior = today.minus({ months: 1 }).startOf('month');
        const fimMesSeguinte = today.plus({ months: 1 }).endOf('month');

        setFirstDate(inicioMesAnterior);
        setLastDate(fimMesSeguinte);
    };

    return (
        <CalendarContext.Provider
            value={{
                dates,
                setDates,
                lastDate,
                setLastDate,
                firstDate,
                setFirstDate,
                events,
                setEvents,
                generateDatesRange,
                clearDates,
                showMonthlyView,
                setShowMonthlyView,
                resetDates,
                view, 
                setView,
                setarAgendaTipo, 
                filterCanceled,
                filterHolidays,
                toggleFilterCanceled,
                toggleFilterHolidays,
                shouldRefreshEvents,
                setShouldRefreshEvents,
                triggerRefresh,
                showNotAttended,
                filterNotAttended,
                toggleFilterNotAttended,
                showWeekend,
                toggleShowWeekend,
                filterRealized,
                toggleFilterRealized,
            }}
        >
            {children}
        </CalendarContext.Provider>
    );
};
