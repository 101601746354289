import { createSlice } from '@reduxjs/toolkit';

export const permissionSlice = createSlice({
    name: 'base/permissions',
    initialState: {
        permission: {
            id: 0,
            permissoes: [],
            acoes: [],
            numeroPacientes: 0,
            numeroPacientesPlano: 0,
            tipo: '',
        },
    },
    reducers: {
        setPermissions: (state, action) => {
            state.permission = action.payload;
        },
    },
});

export const { setPermissions } = permissionSlice.actions;

export default permissionSlice.reducer;
