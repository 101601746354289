import appConfig from 'configs/app.config';
import ApiService from './ApiService';
import BaseService from './BaseService';

export async function _enviarArquivo(tipoDocumento, file) {
    const x = new FormData();
    x.append('file', file);

    return BaseService.post(
        `${appConfig.apiPrefix}/rest/contaPagamento/upload/${tipoDocumento}`,
        x,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
}

export const _criarContaPagamento = async (data) => {
    return ApiService.fetchData({
        url: '/rest/contaPagamento',
        method: 'post',
        data,
    });
};

export const _criarContaPagamentoSimplificada = async (data) => {
    return ApiService.fetchData({
        url: 'rest/contaPagamento/cadastroSimplificado',
        method: 'post',
        data,
    });
};

export const _buscarContaPagamento = async () => {
    return ApiService.fetchData({
        url: '/rest/contaPagamento',
        method: 'get',
    });
};

export const _buscarMeioPagamentoInfo = async () => {
    return ApiService.fetchData({
        url: '/rest/contaPagamento/mpinfo',
        method: 'get',
    });
};

export const _cobrancaTeste = async () => {
    return ApiService.fetchData({
        url: '/rest/pagamento/cobrancaTeste',
        method: 'post',
    })
}

export const _atualizarDadosBancarios = async ({ pixKey, pixTipo }) => {
    return ApiService.fetchData({
        url: `/rest/contaPagamento/${pixTipo}/${pixKey}`,
        method: 'put',
    });
};
