import React, {
    useRef,
    useCallback,
    FunctionComponent,
    CSSProperties,
    FormEvent,
    LegacyRef,
    ForwardedRef,
} from 'react';
import DropdownMenu from './DropdownMenu';
import DropdownToggle from './DropdownToggle';
import useUniqueId from '../hooks/useUniqueId';
import DropdownContext from './context/dropdownContext';
import DropdownMenuContext, {
    useDropdownMenuContext,
} from './context/dropdownMenuContext';
import chainedFunction from '../utils/chainedFunction';
import useRootClose from '../hooks/useRootClose';
import arrayIndexOf from '../utils/arrayIndexOf';
import { Placement } from '../../../types/ui';

const CLICK = 'click';
const HOVER = 'hover';
const CONTEXT = 'context';

interface OwnProps {
  trigger?: 'click' | 'hover' | 'context';
  placement?: Placement;
  menuClass?: string;
  menuStyle?: StyleSheet;
  disabled?: boolean;
  title?: string;
  className?: string;
  renderTitle?: React.ReactNode;
  activeKey?: string;
  toggleClassName?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onContextMenu?: () => void;
  onSelect?: (eventKey: string, e: FormEvent) => void;
  onOpen?: () => void;
  onClose?: () => void;
  onToggle?: (nextOpen: boolean) => void;
  children?: React.ReactNode;
  style?: StyleSheet;
  ref?: ForwardedRef<unknown>;
  stayOpenOnSelect?: boolean; 
}

const Dropdown: any = React.forwardRef((props: OwnProps, refDropdown) => {
    const {
        title,
        children,
        className,
        menuClass,
        menuStyle,
        disabled,
        renderTitle,
        placement,
        activeKey,
        toggleClassName,
        trigger,
        style,
        onClick,
        onMouseEnter,
        onMouseLeave,
        onContextMenu,
        onSelect,
        onOpen,
        onClose,
        onToggle,
        stayOpenOnSelect = false, 
        ref = refDropdown,
        ...rest
    } = props;

    const overlayTarget = useRef<HTMLDivElement | null>(null);
    const triggerTarget = useRef<HTMLDivElement | null>(null);
    const auxTrigger = trigger || 'click';
    const menuControl = useDropdownMenuContext(overlayTarget);
    const { open } = menuControl;

    const buttonId = useUniqueId('dropdown-toggle-');
    const menuId = useUniqueId('base-menu-');

    const handleToggle = useCallback(
        (isOpen?: boolean) => {
            const nextOpen = typeof isOpen === 'undefined' ? !open : isOpen;
            const fn = nextOpen ? onOpen : onClose;

            fn?.();
            onToggle?.(nextOpen);
            if (nextOpen) {
                menuControl.openMenu();
            } else {
                menuControl.closeMenu();
            }
        },
        [onClose, onOpen, onToggle, open, menuControl],
    );

    const handleClick = useCallback(
        (e: FormEvent) => {
            e.preventDefault();
            if (disabled) {
                return;
            }
            handleToggle();
        },
        [disabled, handleToggle],
    );

    const handleMouseEnter = useCallback(() => {
        if (!disabled) {
            handleToggle(true);
        }
    }, [disabled, handleToggle]);

    const handleMouseLeave = useCallback(() => {
        if (!disabled) {
            handleToggle(false);
        }
    }, [disabled, handleToggle]);

    const handleSelect = (eventKey: string, e: FormEvent) => {
        onSelect?.(eventKey, e);
        if (!stayOpenOnSelect) {
            handleToggle(false);
        }
    };

    useRootClose(() => handleToggle(), {
        triggerTarget,
        overlayTarget,
        disabled: !open,
        listenEscape: false,
    });

    const dropdownProps = {
        onMouseEnter,
        onMouseLeave,
    };

    const toggleEventHandlers = {
        onClick,
        onContextMenu,
    };

    if (arrayIndexOf(CLICK, auxTrigger)) {
        toggleEventHandlers.onClick = chainedFunction(
            handleClick,
            toggleEventHandlers.onClick,
        );
    }

    if (arrayIndexOf(CONTEXT, auxTrigger)) {
        toggleEventHandlers.onContextMenu = chainedFunction(
            handleClick,
            onContextMenu,
        );
    }

    if (arrayIndexOf(HOVER, auxTrigger)) {
        dropdownProps.onMouseEnter = chainedFunction(
            handleMouseEnter,
            onMouseEnter,
        );
        dropdownProps.onMouseLeave = chainedFunction(
            handleMouseLeave,
            onMouseLeave,
        );
    }

    const toggleElement = (
        <DropdownToggle
            {...rest}
            {...toggleEventHandlers}
            id={buttonId}
            ref={triggerTarget}
            className={toggleClassName}
            renderTitle={renderTitle}
            disabled={disabled}
            placement={placement || 'bottom-start'}
        >
            {title}
        </DropdownToggle>
    );

    const menuElement = (
        <DropdownMenu
            className={menuClass}
            style={menuStyle}
            onSelect={handleSelect}
            activeKey={activeKey}
            ref={overlayTarget}
            hidden={!open}
            placement={placement || 'bottom-start'}
            id={menuId}
        >
            {children}
        </DropdownMenu>
    );

    return (
        <DropdownContext.Provider value={{ activeKey }}>
            <div
                {...dropdownProps}
                style={{ style } as CSSProperties}
                className="dropdown"
                ref={ref as LegacyRef<HTMLDivElement> | undefined}
            >
                {toggleElement}
                <DropdownMenuContext.Provider value={menuControl}>
                    {menuElement}
                </DropdownMenuContext.Provider>
            </div>
        </DropdownContext.Provider>
    );
});

export default Dropdown;
