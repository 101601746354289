import React, {
    createContext, useContext, useState, ReactNode, useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { tutorialsConfig } from 'configs/tutorialRoutes';
import navigationConfig from 'configs/navigation.config';
import { _startOnboard, _endOnboard, _ativarUsuario } from 'services/TutorialService';
import useAuth from 'utils/hooks/useAuth';

interface TutorialContextProps {
    isTutorialMode: boolean;
    isTutorialVisible: boolean;
    currentTutorial: string | null;
    showCongratsDialog: boolean;
    tutorialComplete: boolean;
    nomeTelaFinal?: string;
    isHelpVisible: boolean;
    isCorporaBio: boolean;
    currentHelpPage: string | null;
    helpType: string | null;
    paramFinal?: string;
    textoEntrada?: string;
    isTutorialMessageVisible: boolean;
    abrirCadastro: boolean;  
    isOnboardingDialogVisible: boolean;
    isMobile: boolean;  
    setIsOnboardingDialogVisible: (mode: boolean) => void;
    setIsTutorialVisible: (mode: boolean) => void;
    setIsTutorialMode: (mode: boolean) => void;
    selectTutorial: (tutorialKey: string) => void;
    getFilteredNavigationConfig: () => NavigationItem[];
    goToNextTutorialStep: () => void;
    goToPreviousTutorialStep: () => void;
    getCurrentStepData: () => { url: string; nome: string } | null;
    setShowCongratsDialog: (visible: boolean) => void;
    setTutorialComplete: (complete: boolean) => void;
    setIsHelpVisible: (visible: boolean) => void;
    setIsCorporaBio: (visible: boolean) => void;
    setCurrentHelpPage: (page: string | null) => void;
    setHelpType: (type: string | null) => void;
    setIsTutorialMessageVisible: (visible: boolean) => void;
    setAbrirCadastro: (open: boolean) => void;
}

interface NavigationItem {
    path: string;
    subMenu?: NavigationItem[];
}

const TutorialContext = createContext<TutorialContextProps | undefined>(undefined);

const filterNavigationItems = (items: NavigationItem[], accessibleRoutes: string[]): NavigationItem[] => {
    return items.reduce<NavigationItem[]>((acc, item) => {
        let filteredSubMenu: NavigationItem[] = [];

        if (item.subMenu && item.subMenu.length > 0) {
            filteredSubMenu = filterNavigationItems(item.subMenu, accessibleRoutes);
        }

        const isAccessibleRoute = accessibleRoutes.includes(item.path);

        if (isAccessibleRoute || filteredSubMenu.length > 0) {
            acc.push({
                ...item,
                subMenu: filteredSubMenu,
            });
        }

        return acc;
    }, []);
};

export const TutorialProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isTutorialMode, setIsTutorialMode] = useState<boolean>(
        () => JSON.parse(localStorage.getItem('isTutorialMode') || 'false'),
    );

    const [isTutorialVisible, setIsTutorialVisibleState] = useState<boolean>(
        () => JSON.parse(localStorage.getItem('isTutorialVisible') || 'false'),
    );
    const [accessibleRoutes, setAccessibleRoutes] = useState<string[]>(
        () => JSON.parse(localStorage.getItem('accessibleRoutes') || '[]'),
    );
    const [nomeTelaFinal, setNomeTelaFinal] = useState<string | undefined>(
        () => localStorage.getItem('nomeTelaFinal') || undefined,
    );
    const [paramFinal, setParamFinal] = useState<string | undefined>(
        () => localStorage.getItem('paramFinal') || undefined,
    );

    const [currentTutorial, setCurrentTutorial] = useState<string | null>(
        () => localStorage.getItem('currentTutorial') || null,
    );
    const [showCongratsDialog, setShowCongratsDialog] = useState<boolean>(false);
    const [tutorialComplete, setTutorialComplete] = useState<boolean>(false);
    const [isCorporaBio, setIsCorporaBio] = useState<boolean>(false);
    const [isHelpVisible, setIsHelpVisibleState] = useState<boolean>(false);
    const [currentHelpPage, setCurrentHelpPage] = useState<string | null>(null);
    const [helpType, setHelpType] = useState<string | null>(null);
    const [textoEntrada, setTextoEntrada] = useState<string | undefined>(undefined);
    const [isTutorialMessageVisible, setIsTutorialMessageVisible] = useState(false);
    const [abrirCadastro, setAbrirCadastro] = useState(false); 
    const [isOnboardingDialogVisible, setIsOnboardingDialogVisible] = useState(false);
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 1024);

    const navigate = useNavigate();
    const { retoken } = useAuth();

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 1024);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const setIsTutorialVisible = (visible: boolean) => {
        setIsTutorialVisibleState(visible);
        if (visible) {
            setIsHelpVisibleState(false);
        }
    };

    const setIsHelpVisible = (visible: boolean) => {
        setIsHelpVisibleState(visible);
        if (visible) {
            setIsTutorialVisibleState(false);
        }
    };

    const selectTutorial = async (tutorialKey: string) => {
        const tutorialConfig = tutorialsConfig[tutorialKey];
    
        if (!tutorialConfig || tutorialConfig.rotas.length === 0) {
            console.error('Tutorial não encontrado ou vazio:', tutorialKey);
            return;
        }

        setCurrentTutorial(tutorialKey);

        
        setAbrirCadastro(tutorialConfig.rotas[0].abrirCadastro || false); 
        setAccessibleRoutes([tutorialConfig.rotas[0].url]);
        setTextoEntrada(tutorialConfig.rotas[0].textoEntrada);
        setIsTutorialMessageVisible(!!tutorialConfig.rotas[0].textoEntrada)
        setNomeTelaFinal(tutorialConfig.nomeTelaFinal);
        setIsTutorialMode(true);
        setTutorialComplete(false);

        try {
            await _startOnboard(tutorialConfig.nomeService);
            console.log(`Onboarding iniciado para: ${tutorialConfig.nomeService}`);
        } catch (error) {
            console.error(`Erro ao iniciar o onboarding para: ${tutorialConfig.nomeService}`, error);
        }

        if (tutorialConfig.nomeService === 'CORPORA_BIO') {
            setIsCorporaBio(true);
        } else {
            setIsCorporaBio(false);
        }
    
        if (isMobile) {
            setIsTutorialVisible(false);
        } else {
            setIsTutorialVisible(true);
        }
    
        navigate(tutorialConfig.rotas[0].url);
    };
    
    const goToNextTutorialStep = async () => {
        if (!currentTutorial) {
            console.error('Nenhum tutorial atual foi definido');
            return;
        }

        const tutorialConfig = tutorialsConfig[currentTutorial];

        if (!tutorialConfig) {
            console.error('Configuração do tutorial não encontrada para:', currentTutorial);
            return;
        }

        const currentRouteIndex = tutorialConfig?.rotas.findIndex(
            (route) => route.url === accessibleRoutes[accessibleRoutes.length - 1],
        );

        if (currentRouteIndex < tutorialConfig?.rotas.length - 1) {
            const nextRoute = tutorialConfig.rotas[currentRouteIndex + 1];
            setAccessibleRoutes((prev) => [...prev, nextRoute.url]);
            setShowCongratsDialog(false);
            setTextoEntrada(nextRoute.textoEntrada);
            setIsTutorialMessageVisible(!!nextRoute.textoEntrada);
            setAbrirCadastro(nextRoute.abrirCadastro || false);
            navigate(nextRoute.url);
        } else if (tutorialConfig.telaFinal) {
            console.log('next');
            try {
                if (tutorialConfig.nomeService === 'CORPORA_BIO') {
                    console.log('next3');
                    await _ativarUsuario();
                    retoken();
                    const endResponse = await _endOnboard();
                    if (endResponse && endResponse.data[0].param) {
                        setParamFinal(endResponse.data[0].param);
                    }
                    setShowCongratsDialog(true);
                    setTutorialComplete(true);
                    setIsTutorialMode(false);
                }

                if (tutorialConfig.telaFinal) {
                    navigate(tutorialConfig.telaFinal);
                } else {
                    console.error('Nenhuma tela final configurada.');
                }
            } catch (error) {
                console.error('Erro ao finalizar o onboarding:', error);
            }
            setShowCongratsDialog(true);
            setTutorialComplete(true);
            setIsTutorialMode(false);
        } else {
        }
        console.log('showCongratsDialog', showCongratsDialog);
    };

    const goToPreviousTutorialStep = () => {
        const tutorialConfig = tutorialsConfig[currentTutorial || ''];
        const currentRouteIndex = tutorialConfig?.rotas.findIndex(
            (route) => route.url === accessibleRoutes[accessibleRoutes.length - 1],
        );

        if (currentRouteIndex > 0) {
            const previousRoute = tutorialConfig.rotas[currentRouteIndex - 1];
            setAccessibleRoutes((prev) => prev.slice(0, -1));
    
            setTextoEntrada(previousRoute.textoEntrada);
            setIsTutorialMessageVisible(!!previousRoute.textoEntrada);
    
            navigate(previousRoute.url);
        }
    };

    const getCurrentStepData = (): { url: string; nome: string } | null => {
        const tutorialConfig = tutorialsConfig[currentTutorial || ''];
        const currentRoute = accessibleRoutes[accessibleRoutes.length - 1];
        return tutorialConfig?.rotas.find((route) => route.url === currentRoute) || null;
    };

    const getFilteredNavigationConfig = (): NavigationItem[] => {
        return filterNavigationItems(navigationConfig, accessibleRoutes);
    };

    useEffect(() => {
        localStorage.setItem('isTutorialMode', JSON.stringify(isTutorialMode));
    }, [isTutorialMode]);

    useEffect(() => {
        localStorage.setItem('isTutorialVisible', JSON.stringify(isTutorialVisible));
    }, [isTutorialVisible]);

    useEffect(() => {
        localStorage.setItem('currentTutorial', currentTutorial || '');
    }, [currentTutorial]);

    useEffect(() => {
        localStorage.setItem('accessibleRoutes', JSON.stringify(accessibleRoutes));
    }, [accessibleRoutes]);

    useEffect(() => {
        localStorage.setItem('nomeTelaFinal', nomeTelaFinal || '');
    }, [nomeTelaFinal]);

    useEffect(() => {
        localStorage.setItem('paramFinal', paramFinal || '');
    }, [paramFinal]);

    return (
        <TutorialContext.Provider
            value={{
                isTutorialMode,
                isTutorialVisible,
                currentTutorial,
                showCongratsDialog,
                tutorialComplete,
                nomeTelaFinal,
                isHelpVisible,
                currentHelpPage,
                helpType,
                paramFinal,
                isCorporaBio,
                textoEntrada,
                isTutorialMessageVisible,
                abrirCadastro,
                isOnboardingDialogVisible, 
                isMobile,
                setIsOnboardingDialogVisible,
                setAbrirCadastro,
                setIsTutorialMessageVisible,
                setIsCorporaBio,
                setHelpType,
                setCurrentHelpPage,
                setIsHelpVisible,
                setIsTutorialVisible,
                setIsTutorialMode,
                selectTutorial,
                goToPreviousTutorialStep,
                goToNextTutorialStep,
                getCurrentStepData,
                getFilteredNavigationConfig,
                setShowCongratsDialog,
                setTutorialComplete,
            }}
        >
            {children}
        </TutorialContext.Provider>
    );
};

export const useTutorial = (): TutorialContextProps => {
    const context = useContext(TutorialContext);
    if (!context) {
        throw new Error('useTutorial must be used within a TutorialProvider');
    }
    return context;
};
