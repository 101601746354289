import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import {
    _apagarAgenda,
    _cancelamentoAgenda,
    _iniciarReuniaoAgenda,
    _naoCompareceuAgenda,
    _reagendar,
    _realizarAgenda,
    _recuperarAgenda,
} from 'services/AgendaService';
import { RootState } from 'store';
import { setEventos, setEventosHistorico } from 'store/base/clienteSlice';
import { ConfigAgenda } from 'types/calendario/configAgenda';
import { toast as toastfy } from 'react-toastify';
import { STATUS_AGENDA } from 'constants/calendario.constant';

const JSON_DATE_FORMAT = 'yyyyMMdd';

const useDialog = () => {
    const dispatch = useDispatch();
    const clienteEventos = useSelector((state: RootState) => state.base.cliente);

    const apagarAgendaRecorrenteClick = async (
        id: number,
        tipoExclusao: number,
        onSuccess: () => void,
    ) => {
        const response = await _apagarAgenda(id, tipoExclusao);
        const eventosLista = JSON.parse(JSON.stringify(clienteEventos.eventos));

        response.data.forEach((agendaRemovida: ConfigAgenda) => {
            const dataStr = DateTime.fromFormat(
                agendaRemovida.dataInicio,
                'dd/MM/yyyy HH:mm',
            ).toFormat(JSON_DATE_FORMAT);

            if (!agendaRemovida.id || !eventosLista[dataStr]) return;

            const eventoEscolhido = eventosLista[dataStr][agendaRemovida.id];
            eventoEscolhido.status = STATUS_AGENDA.REMOVIDO;
        });

        dispatch(setEventos(eventosLista));
        onSuccess();
    };

    const naoCompareceuConfirma = async (
        data: ConfigAgenda,
        onSuccess: () => void,
        cobrar: number,
        enviarMensagem: number,
    ) => {
        if (!data.id) return;
        try {
            await _naoCompareceuAgenda(data.id, cobrar, enviarMensagem);

            updateEventoStore(data, data.id, STATUS_AGENDA.NAO_COMPARECEU);

            onSuccess();

            toastfy.success('Agenda marcada com não comparecimento!');
        } catch (e) {
            toastfy.error(
                'Algo deu errado e não foi possível computar a ausência do paciente. Tente mais tarde.',
            );
        }
    };

    const cancelamentoConfirma = async (
        data: ConfigAgenda,
        onSucess: () => void,
        responsavel: number,
        cobrar: number,
        cancelarAgendasSeguintes: number,
        enviarMensagem: number,
    ) => {
        if (!data?.id) return;

        try {
            const response = await _cancelamentoAgenda(data?.id, responsavel, cobrar, cancelarAgendasSeguintes, enviarMensagem);
            const evt = response.data;

            console.log('response', response);

            updateEventoStore(data, evt.id, STATUS_AGENDA.CANCELADO);

            onSucess();
            toastfy.success('Consulta/Atendimento cancelado com sucesso!');
        } catch (e) {
            toastfy.error(
                'Algo deu errado e não foi possível cancelar a Consulta/Atendimento. Tente mais tarde.',
            );
        }
    };

    const reagendamentoConfirmaClick = async (
        data: ConfigAgenda,
        onSucess: () => void,
    ) => {
        if (!data?.id) return;

        try {
            await _reagendar(data?.id);
            onSucess();
            toastfy.success('Reagendamento solicitado com sucesso!');
        } catch (e) {
            toastfy.error(
                'Algo deu errado e não foi possível solicitar o reagendamento. Tente mais tarde.',
            );
        }
    };

    const recuperacaoConfirmaClick = async (
        data: ConfigAgenda,
        onSucess: () => void,
    ) => {
        if (!data) return;

        try {
            await _recuperarAgenda(data?.id);
            onSucess();

            toastfy.success('Agenda recuperada com sucesso!');
        } catch (e) {
            toastfy.error(
                'Algo deu errado e não foi possível recuperar a agenda. Tente mais tarde.',
            );
        }
    };

    const realizarConfirmaClick = async (
        data: ConfigAgenda,
        onSucess: () => void,
        cobrar: number,
    ) => {
        try {
            const response = await _realizarAgenda(data?.id, cobrar);

            const evt = response.data;
            updateEventoStore(data, evt.id, STATUS_AGENDA.REALIZADO);

            onSucess();
            toastfy.success('Agenda marcada como realizada!');
        } catch (e) {
            toastfy.error(
                'Algo deu errado e não foi possível realizar a agenda. Tente mais tarde.',
            );
        }
    };

    const acessarReuniaoConfirmaClick = async (
        data: ConfigAgenda,
        onSuccess: () => void,
    ) => {
        try {
            if (!data?.id) return;
            _iniciarReuniaoAgenda(data?.id);
            window.open(`https://meet.jit.si/${data?.uuid}`, '_blank')?.focus();
            onSuccess();
        } catch (e) {
            toastfy.error(
                'Algo deu errado e não foi possível realizar a agenda. Tente mais tarde.',
            );
        }
    };

    const updateEventoStore = (
        data: ConfigAgenda,
        id: number,
        status: STATUS_AGENDA,
    ) => {
        const dataStr = DateTime.fromFormat(
            data.dataInicio,
            'dd/MM/yyyy HH:mm',
        ).toFormat(JSON_DATE_FORMAT);
        const eventosLista = JSON.parse(JSON.stringify(clienteEventos.eventos));
        const eventosHis = JSON.parse(
            JSON.stringify(clienteEventos.eventosHistorico),
        );

        const eventoEscolhido = eventosLista?.[dataStr]?.[id];
        const eventoEscolhidoHis = eventosHis?.[dataStr]?.[id];

        if (eventoEscolhido) {
            eventoEscolhido.status = status;
            dispatch(setEventos(eventosLista));
        }
        if (eventoEscolhidoHis) {
            eventoEscolhidoHis.status = status;
            dispatch(setEventosHistorico(eventosHis));
        }
    };

    const apagarAgendaSimples = async (
        data: ConfigAgenda,
        onSucess: () => void,
    ) => {
        if (!data) return;

        const response = await _apagarAgenda(data.id, 0);
        const evt = response.data[0];

        updateEventoStore(data, evt.id, STATUS_AGENDA.REMOVIDO);

        onSucess();
        const message = data.categoria === 'BLOQUEIO' ? 'Bloqueio excluído' : 'Agenda excluída';
        toastfy.success(message);
    };

    return {
        apagarAgendaRecorrenteClick,
        naoCompareceuConfirma,
        cancelamentoConfirma,
        reagendamentoConfirmaClick,
        recuperacaoConfirmaClick,
        realizarConfirmaClick,
        acessarReuniaoConfirmaClick,
        apagarAgendaSimples,
    };
};

export default useDialog;
