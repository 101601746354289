import { Button, Dialog, Switcher } from 'components/ui';
import { useEffect, useState } from 'react';
import SwitcherDouble from 'components/ui/Switcher/SwitcherDouble';
import useDialog from 'views/calendario/hooks/useDialog';
import useMeioPagamentoInfo from 'views/calendario/hooks/usePaymentInfo';
import { ConfigAgenda } from 'types/calendario/configAgenda';

interface DialogProps {
    data: ConfigAgenda | null;
    isOpen: boolean;
    onClose: () => void;
    onSuccess: () => void;
    cancelamentoConfirmaClick: (options: {
        cobrar: number;
        responsavel: 'profissional' | 'paciente';
        cancelarAgendasSeguintes: number;
    }) => void;
}

const DialogConfirmarCancelamento = ({
    data,
    isOpen,
    onSuccess,
    onClose,
}: DialogProps) => {
    const [cobrar, setCobrar] = useState<number>(1);
    const [responsavel, setResponsavel] = useState<'profissional' | 'paciente'>('paciente');
    const [cancelarAgendasSeguintes, setCancelarAgendasSeguintes] = useState<number>(0);
    const [enviarMensagem, setEnviarMensagem] = useState<number>(0);
    const [showError, setShowError] = useState<boolean>(false);

    const { cancelamentoConfirma } = useDialog();
    const { data: meioPagamentoInfo, error } = useMeioPagamentoInfo();

    const isValorInvalid = !data?.valor || isNaN(Number(data.valor)) || Number(data.valor) === 0;
    
    const isDisabled = error?.response?.status === 400 || isValorInvalid;

    useEffect(() => {
        if (isOpen) {
            setCobrar(1);
            setResponsavel('paciente');
            setCancelarAgendasSeguintes(0);
            setEnviarMensagem(0);
            setShowError(false);
        }
    }, [isOpen]);

    const handleCancelarAgendasSeguintes = () => {
        if (cancelarAgendasSeguintes === 1) {
            setCancelarAgendasSeguintes(0);
        } else {
            setCancelarAgendasSeguintes(1);
            setCobrar(1);
            setEnviarMensagem(0);
        }
    };

    const handleCobrarChange = (value: number) => {
        setCobrar(value);
        setShowError(false);
    };

    const handleConfirm = () => {
        if (cobrar === 0) {
            setShowError(true);
            return;
        }

        const isResponsavelPaciente = responsavel === 'paciente' ? 0 : 1;
        cancelamentoConfirma(data!, onSuccess, isResponsavelPaciente, cobrar, cancelarAgendasSeguintes, enviarMensagem);
    };

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            onRequestClose={onClose}
            overlayClassName="z-40"
        >
            <div className="p-6 bg-white">
                <h6 className="mb-4 font-medium">Cancelamento de agenda: {data?.dataInicio}</h6>

                {data?.agrupamento && (
                    <div className="flex items-center gap-4 my-4">
                        <Switcher
                            checked={cancelarAgendasSeguintes === 1}
                            onChange={handleCancelarAgendasSeguintes}
                        />
                        <span>Marcar agendas seguintes como canceladas</span>
                    </div>
                )}

                <div className="mt-10 mb-10">
                    <div className="flex flex-col gap-4 mt-2">
                        <div className="flex items-center">
                            <Switcher
                                checked={cobrar === 1}
                                onChange={() =>
                                    handleCobrarChange(cobrar === 1 && cancelarAgendasSeguintes === 0 ? 0 : 1)
                                }
                                disabled={cancelarAgendasSeguintes === 1}
                            />
                            <span
                                className={`ml-3 ${
                                    cancelarAgendasSeguintes === 1 ? 'text-gray-300' : ''
                                }`}
                            >
                                Excluir o lançamento. Não irei cobrar
                            </span>
                        </div>

                        {cobrar !== 1 && cancelarAgendasSeguintes === 0 && (
                            <>
                                <span>Escolha uma das opções abaixo</span>
                                <div className="flex items-center">
                                    <Switcher
                                        checked={cobrar === 2}
                                        onChange={() => handleCobrarChange(2)}
                                        disabled={isDisabled}
                                    />
                                    <span
                                        className={`ml-3 ${
                                            isDisabled ? 'text-gray-300' : 'text-gray-900'
                                        }`}
                                        title={isDisabled ? 'Você ainda não aderiu ao Corpora Bank' : ''}
                                    >
                                        Aplicar cobrança pela Corpora agora
                                    </span>
                                </div>
                                <div className="flex items-center">
                                    <Switcher
                                        checked={cobrar === 3}
                                        onChange={() => handleCobrarChange(3)}
                                    />
                                    <span className="ml-3">Vou cobrar o paciente manualmente</span>
                                </div>
                                <div className="flex items-center mb-2">
                                    <Switcher
                                        checked={cobrar === 4}
                                        onChange={() => handleCobrarChange(4)}
                                    />
                                    <span className="ml-3">Já recebi o valor do paciente</span>
                                </div>
                            </>
                        )}
                    </div>

                    {showError && (
                        <span className="text-red-500 mt-3">
                            Selecione uma opção de cobrança
                        </span>
                    )}
                </div>

                <div className="flex items-center mt-4">
                    <Switcher
                        checked={enviarMensagem === 1}
                        onChange={() => setEnviarMensagem(enviarMensagem === 1 ? 0 : 1)}
                        disabled={cancelarAgendasSeguintes === 1}
                    />
                    <span
                        className={`ml-3 ${
                            cancelarAgendasSeguintes === 1 ? 'text-gray-300' : ''
                        }`}
                    >
                        Enviar mensagem de reagendamento
                    </span>
                </div>

                <div className="gap-4 my-4 mt-10">
                    <span>Responsável pelo cancelamento</span>
                    <div className="flex gap-4 mt-4">
                        <span className="text-right">Profissional</span>
                        <SwitcherDouble
                            checked={responsavel === 'paciente'}
                            onChange={() =>
                                setResponsavel(responsavel === 'profissional' ? 'paciente' : 'profissional')
                            }
                        />
                        <span className="text-left">Paciente</span>
                    </div>
                </div>

                <p className="mb-5 mt-10">
                    Esse cancelamento <b>não poderá ser desfeito</b>. Confirma?
                </p>

                <div className="text-right mt-6 flex flex-col md:flex-row gap-2">
                    <Button variant="solid" onClick={handleConfirm}>
                        Confirmar
                    </Button>
                    <Button onClick={onClose}>Cancelar</Button>
                </div>
            </div>
        </Dialog>
    );
};

export default DialogConfirmarCancelamento;
