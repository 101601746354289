import { FaCheck } from 'react-icons/fa';

interface TopicItemProps {
    isSubItem?: boolean;
    title: string;
    description?: string;
    checked?: boolean;
}

const TopicItem = ({
    title,
    isSubItem,
    description,
    checked,
}: TopicItemProps) => {
    return (
        <div className={`flex gap-3 mb-4 ${isSubItem && 'ml-4'}`}>
            {checked ? (
                <div className="w-6 h-6 rounded-full border-4 border-indigo-400 bg-indigo-400 ">
                    <FaCheck className="text-white mt-[1px] ml-[1px]" />
                </div>
            ) : (
                <div className="w-6 h-6 rounded-full border-2 border-gray-400" />
            )}
            <div className="flex flex-col">
                <span className="text-base font-semibold">{title}</span>
                {description && <span className="leading-4">{description}</span>}
            </div>
        </div>
    );
};

export default TopicItem;
