import { Button, Dialog } from 'components/ui';
import { ConfigAgenda } from 'types/calendario/configAgenda';
import useDialog from 'views/calendario/hooks/useDialog';

interface DialogProps {
  data: ConfigAgenda | null;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const DialogoExclusaoAgenda = ({
    data,
    isOpen,
    onClose,
    onSuccess,
}: DialogProps) => {
    const { apagarAgendaRecorrenteClick } = useDialog();

    /**
   *
   * @param exclusaoTipo
   * 1: Somente esta
   * 2: Esta e as seguintes
   *
   */
    const handleExcluirAgenda = (exclusaoTipo: number) => {
        if (!data?.id) return;
        apagarAgendaRecorrenteClick(data.id, exclusaoTipo, onSuccess);
    };

    const tipoAg: {
    [key: string]: string;
  } = {
      BLOQUEIO: 'bloqueio',
      NORMAL: 'agenda',
  };

    return (
        <Dialog
            overlayClassName="z-40"
            isOpen={isOpen}
            onClose={onClose}
            onRequestClose={onClose}
        >
            <h6 className="mb-4 font-medium">
                Exclusão de {tipoAg[data?.categoria as string]} {data?.dataInicio}
            </h6>
            <p>
                {data?.categoria === 'NORMAL' ? 'Esta' : 'Este'} é{' '}
                {data?.categoria === 'NORMAL' ? 'uma' : 'um'}{' '}
                {tipoAg[data?.categoria as string]} recorrente. O que deseja excluir?
            </p>
            <div className="text-right mt-6 flex flex-col md:flex-row gap-2">
                <Button variant="solid" onClick={() => handleExcluirAgenda(0)}>
                    Somente Esta
                </Button>
                <Button
                    variant="solid"
                    className=""
                    onClick={() => handleExcluirAgenda(1)}
                >
                    Essa e as Seguintes
                </Button>
                <Button onClick={onClose}>Cancelar</Button>
            </div>
        </Dialog>
    );
};

export default DialogoExclusaoAgenda;
