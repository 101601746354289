import React from 'react';
import classNames from 'classnames';
import { SIZES } from '../utils/constant';

const Line = (props) => {
    const {
        percent, size, children, strokeColor,
    } = props;

    const sizes = {
        xs: 'h-1',
        sm: 'h-1.5',
        md: 'h-2',
        lg: 'h-2.5',
        xl: 'h-5',
        '2xl': 'h-10',
        '3xl': 'h-15',
    };

    const progressBackgroundClass = classNames(
        'progress-bg ',
        `${sizes[size || 'md']}`,
        `bg-${strokeColor}`,
    );

    return (
        <>
            <div className="progress-wrapper">
                <div className="progress-inner">
                    <div
                        className={progressBackgroundClass}
                        style={{ width: `${percent}%` }}
                    />
                </div>
            </div>
            {children}
        </>
    );
};

export default Line;
