import { Button, Dialog } from 'components/ui';
import { HiExclamation } from 'react-icons/hi';
import { ConfigAgenda } from 'types/calendario/configAgenda';
import useDialog from 'views/calendario/hooks/useDialog';

interface DialogProps {
  data: ConfigAgenda | null;
  isOpen: boolean;
  onClose: () => void;
  onSucess: () => void;
}

const DialogConfirmarReagendar = ({
    data,
    isOpen,
    onClose,
    onSucess,
}: DialogProps) => {
    const { reagendamentoConfirmaClick } = useDialog();

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            onRequestClose={onClose}
            overlayClassName="z-40"
        >
            {data && (
                <h6 className="mb-4 font-medium">
                    Confirmação de reagendamento: {data?.dataInicio}
                </h6>
            )}
            <div className="flex justify-center mb-5">
                <HiExclamation size={55} color="#ffcc00" />
            </div>
            <p className="mb-5">
                Enviaremos para o WhastApp do seu paciente uma mensagem para reagendar a
                consulta perdida.
            </p>
            <p>
                Essa opção <b>não é possível de ser desfeita</b>. Confirma?
            </p>
            <div className="text-right mt-6 flex flex-col md:flex-row gap-2">
                <Button
                    variant="solid"
                    onClick={() => {
                        if (!data) return;
                        reagendamentoConfirmaClick(data, onSucess);
                    }}
                >
                    Confirmar
                </Button>
                <Button onClick={onClose}>Cancelar</Button>
            </div>
        </Dialog>
    );
};

export default DialogConfirmarReagendar;
