interface ThemeConfig {
    themeColor: 'indigo';
    direction: 'ltr' | 'rtl';
    mode: 'light' | 'dark';
    primaryColorLevel: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
    cardBordered: boolean;
    panelExpand: boolean;
    controlSize: 'xs' | 'sm' | 'md' | 'lg' | 'lg' | 'xl';
    navMode: 'light' | 'dark' | 'themed' | 'transparent' | undefined;
    layout: {
        type: 'classic' | 'modern' | 'stackedSide' | 'decked' | 'simple' |'blank';
        sideNavCollapse: boolean;
    };
}

/**
 * Since some configurations need to be match with specific themes,
 * we recommend to use the configuration that generated from demo.
 */
export const themeConfig: ThemeConfig = {
    themeColor: 'indigo',
    direction: 'ltr',
    mode: 'light',
    primaryColorLevel: 800,
    cardBordered: true,
    panelExpand: false,
    controlSize: 'sm',
    navMode: 'light',
    layout: {
        type: 'modern',
        sideNavCollapse: !!localStorage.getItem('SIDE_NAV_COLLAPSE') || false,
    },
};
