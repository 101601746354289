import React, { FunctionComponent } from 'react';
import { HiX } from 'react-icons/hi';
import classNames from 'classnames';

interface OwnProps {
  className?: string;
  absolute?: boolean;
  defaultStyle?: boolean;
  svgClass?: string;
  onClick?: () => void;
}

const CloseButton: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        absolute, className, defaultStyle, svgClass, onClick, ...rest
    } = props;
    const closeButtonAbsoluteClass = 'absolute z-10';

    const closeButtonClass = classNames(
        'close-btn',
        (defaultStyle || defaultStyle === undefined) && 'close-btn-default',
        absolute && closeButtonAbsoluteClass,
        className,
    );

    return (
        // eslint-disable-next-line jsx-a11y/interactive-supports-focus
        <span
            className={closeButtonClass}
            role="button"
            onClick={onClick}
            {...rest}
        >
            <HiX />
        </span>
    );
};

export default CloseButton;
